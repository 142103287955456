export enum CustomerReportType {
  ALLOCATION_RUN = "ALLOCATION_RUN",
}

export enum CustomerReportStatus {
  ERROR = "error",
  COMPLETE = "complete",
  ARCHIVED = "archived",
  DELETED = "deleted",
}

interface ICustomerReportConfig {
  report_version: string
  report_location: string | null
  request_id: string | null
  latest_worker_job_id: string | null
  report_start_date: string | null
  report_end_date: string | null
}

export interface ICustomerAllocationRunReportConfig extends ICustomerReportConfig {
  allocation_run_id: string,
}

export interface ICustomerReport {
  id: string
  customer_id: string
  name: string | null
  description: string | null
  customer_report_type: CustomerReportType
  status: CustomerReportStatus
  created_at: string
  updated_at: string | null
  updated_by: string | null
  config: ICustomerAllocationRunReportConfig
}

interface IAllocationRunOutputsArgs {
  customerId?: string,
  programId?: string,
}

interface IAllocationRunOutputsLocations {
  input: string,
  summary: string,
  standardDelivery: string,
  program: string,
  customer: string
}

export interface CustomerReport extends ICustomerReport { }
export class CustomerReport {
  constructor(data: ICustomerReport) {
    Object.assign(this, data);
  }

  get isError(): boolean {
    return this.status === CustomerReportStatus.ERROR;
  }

  get isComplete(): boolean {
    return this.status === CustomerReportStatus.COMPLETE;
  }

  get isArchived(): boolean {
    return this.status === CustomerReportStatus.ARCHIVED;
  }

  get isDeleted(): boolean {
    return this.status === CustomerReportStatus.DELETED;
  }

  getReportOutputsLocations(locationArgs: IAllocationRunOutputsArgs): IAllocationRunOutputsLocations | null {
    const baseReportLocation = this.config.report_location;
    if (this.isError || !baseReportLocation) {
      return null;
    }

    switch (this.customer_report_type) {
      case CustomerReportType.ALLOCATION_RUN:
        // the baseReportLocation that is stored includes the s3 bucket...
        // we don't want the bucket as part of request url's to s3 so trim it.
        const paths = baseReportLocation.split('/');
        paths.shift();
        const trimmedPath = paths.join('/');
        return {
          input: `${trimmedPath}/input.json`,
          summary: `${trimmedPath}/summary_output.json`,
          standardDelivery: `${trimmedPath}/std_delivery_output.json`,
          program: `${trimmedPath}/program/${locationArgs.programId}_output.json`,
          customer: `${trimmedPath}/customer/${locationArgs.customerId}_output.json`,
        }
      default:
        return null;
    }
  }
}
