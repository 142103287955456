import { Box, Button, Group, Notification, TextInput, Transition } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck, IconX } from '@tabler/icons-react';
import { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePatchCustomerMutation } from 'shared/api/customers';
import { useCustomer } from 'shared/store/customers/hooks';
import { dataToDisplayValues, displayValuesToRequestData, errorResponseToDisplayErrors } from 'shared/types/customer';
import { getErrorMessagesFromApiError } from 'shared/utils/data';



export default function EditCustomerView() {
  const { customerId='' } = useParams<{customerId: string}>();
  const res = useCustomer(customerId);
  const customer = res.data;
  const [isLoading, setIsLoading] = useState(false);
  const [edit] = usePatchCustomerMutation();
  const [submittedState, setSubmittedState] = useState<null | 'success' | 'fail'>(null);
  const form = useForm({
    initialValues: dataToDisplayValues(customer),
  });

  useEffect(() => {
    if (customer && !form.isTouched()) {
      form.setValues(dataToDisplayValues(customer))
    }
  });

  const onSubmit = async (values: typeof form.values, e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    try {
      await edit({id: customerId, body: displayValuesToRequestData(values)}).unwrap()
      setSubmittedState('success');
    } catch (err) {
      const errMsgs = getErrorMessagesFromApiError(err);
      if (errMsgs) {
        form.setErrors(errorResponseToDisplayErrors(errMsgs));
      }
      setSubmittedState('fail');
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setSubmittedState(null);
      }, 1200);
    }
  };


  return <div>
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box maw="50vw" ml="lg">
        <div className="customer-edit-form-field-section--container">
          <div className="customer-edit-form-field-section--label">Base fields</div>
          <TextInput
            withAsterisk
            placeholder="Customer name"
            label="Name"
            mb="lg"
            {...form.getInputProps('name')}
          />
          <TextInput
            placeholder="Customer status..."
            label="Status"
            mb="lg"
            {...form.getInputProps('status')}
          />
        </div>
        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit">Submit</Button>
        </Group>
      </Box>
    </form>
    <Transition mounted={submittedState === 'success'}>
      {(transitionStyle) => (
        <Notification onClose={() => setSubmittedState(null)} title="Success" icon={<IconCheck style={{ width: '20px', height: '20px' }} />} color="teal" className="dashboard-global--notification-container" style={transitionStyle}>
          Your changes have been saved
        </Notification>
      )}
    </Transition>
    <Transition mounted={submittedState === 'fail'}>
      {(transitionStyle) => (
        <Notification onClose={() => setSubmittedState(null)} title="Error" icon={<IconX style={{ width: '20px', height: '20px' }} />} color="red" className="dashboard-global--notification-container" style={transitionStyle}>
          We failed to save your changes, please try again
        </Notification>
      )}
    </Transition>
  </div>;
}