import { IDashboardPagination } from './api';
import { IGeneratorAssignment } from './assignment';
import { IGenerator } from './generator';
import { IProgram } from './program';
import { ISubscription } from './subscription';


export interface IAllocationRunInputData {
  programs: IProgram[];
  generators?: IGenerator[];
  customer_subscriptions: ISubscription[];
  generator_assignments: IGeneratorAssignment[];
}

export interface IAllocationRunAccountOutputData {
  results: {
    start_date: string
    account_load_wh: number
    generation_dedicated_wh: number
  }[]
}

export interface IAllocationRunProgramOutputData {
  results: {
    start_date: string
    program_load_wh: number
    program_generation_wh: number
  }[]
}

export enum AllocationStatusReason {
  ERROR__INVALID_CONFIG = 'ERROR__INVALID_CONFIG',
  ERROR__INVALID_ALGO_VERSION = 'ERROR__INVALID_ALGO_VERSION',
  ERROR__NO_INPUT_DATA = 'ERROR__NO_INPUT_DATA',
  ERROR__INTERNAL_ERROR = 'ERROR__INTERNAL_ERROR',

  FINISHED__NO_PROGRAMS = 'FINISHED__NO_PROGRAMS',
  FINISHED__SUCCESSFUL_RUN = 'FINISHED__SUCCESSFUL_RUN',
}

interface IAlgorithmConfig {
  config_version: string
  algorithm_version: string,

  [key: string]: unknown
}

export interface IAlgorithmV1Config extends IAlgorithmConfig {
  year_for_run: number
}

export interface IAlgorithmV2Config extends IAlgorithmConfig {
  year_for_run: number
}

export interface IAlgorithmV3Config extends IAlgorithmConfig {
  start: string
  end: string
}


interface IAllocationRunConfig {
  algorithm_config_version: string
  last_run_worker_job_id: string
  request_id: string
  status_reason?: AllocationStatusReason,
  algorithm_config: IAlgorithmV1Config | IAlgorithmV2Config | IAlgorithmV3Config
}

export enum AllocationRunStatus {
  ERROR = "ERROR",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
  PAUSED = "PAUSED",
  SETTLED = "SETTLED",
}

// TODO: make a class that implements dataToDisplayValues?
export interface IAllocationRun {
  id: string
  customer_id: string
  status: AllocationRunStatus
  created_at: string
  created_by: string
  allocation_config: IAllocationRunConfig
  results_location?: string | null
  description?: string | null
}


export interface IListAllocationRunsResponse {
  data: IAllocationRun[];
  meta: {
    pagination: IDashboardPagination
  }
}

export interface IFetchAllocationRunResponse {
  allocation_run: IAllocationRun;
}


export interface ICreateAllocationRunPayload {
  start_immediately: boolean;
  description: string | null;
  algorithm_config_version: 'v1' | 'v2' | 'v3' | 'v4';
  config: {
    year_for_run?: number;
    start?: string;
    end?: string;
    program_ids?: string[];
  };
}


export const dataToDisplayValues = (run: IAllocationRun) => {
  return {
    yearForRun: (run.allocation_config.algorithm_config.year_for_run || 2021) as number,
    startImmediately: true,
    description: run.description,
  };
};


export const displayValuesToRequestData = (values: ReturnType<typeof dataToDisplayValues>) => {
  return {
    config: {year_for_run: values.yearForRun},
    start_immediately: values.startImmediately,
    description: values.description,
  };
};


export const errorResponseToDisplayErrors = (errs: Record<string, string[]>) => {
  const displayErrors: Record<string, string> = {};

  const fieldMap: Record<string, string> = {
     name: 'name',
     description: 'description',
     // TODO: decompose one field into multiple
     // lat_lng: 'latLng'
     capacity_mw: 'capacityMw',
   };

  Object.entries(errs).forEach(([field, errors]) => {
     if (errors.length && fieldMap[field]) {
       displayErrors[fieldMap[field]] = errors[0];
     }
  });

  return displayErrors;
};


export interface IAllocationResult<T> {
  results: Array<T>
}

export interface IStandardDeliveryResult {
  results_type: string,
  customer_id: string,
  hourly_results: {
    hour: string,
    mix: {
      [fuel: string]: {
        mwh: number,
        sum_co2e_lbs: number,
      },
    },
  }[]
}

export interface ISubscriptionSummary {
  program_id: string,
  subscribed_customer_id: string,
  start: string,
  end: string,
  allocated_generation_mwh: number,
  generation_commit_pct: number,
  program_generation_mwh: number,
  customer_consumption_mwh: number
}

export interface IAllocationSummaryResult {
  results_type: string
  allocation_run_id: string
  output_schema_version: string
  summary_results: {
    num_generators: number,
    total_generation_mwh: number,
    total_program_generation_mwh: number,
    num_customers: number,
    total_load_mwh: number,
    num_programs: number,
    generation_matched_pct: number,
    by_subscription_id: {
      [subscriptionId: string]: ISubscriptionSummary
    },
    hourly_gen_and_load_by_program: {
      [programId: string]: {
        hour: string
        total_gen_wh: number
        load_wh: number
        dedicated_gen_wh: number
        allocated_gen_wh: number
      }[],
    },
  }
}

export interface ICustomerAllocationSummaryResult {
  results_type: string,
  customer_id: string,
  output_schema_version: 'v1',
  hourly_results: {
    datetime: string,
    consumption_wh: number,
    subscription_program_generation: {
      subscription_id: string,
      program_id: string,
      allocated_wh: number,
      dedicated_wh: number,
      excess_gen_wh: number,
      allocated_mwh_by_generator_id: {
        [generatorId: string]: number,
      }
      dedicated_mwh_by_generator_id: {
        [generatorId: string]: number,
      }
    }[],
  }[]
}

interface ISubscriptionAllocationResultDatum {
  customer_id: string,
  consumption_wh: number,
  allocated_wh: number,
  dedicated_wh: number,
  excess_gen_wh: number,
  allocated_mwh_by_generator_id: Record<string, number>,
  dedicated_mwh_by_generator_id: Record<string, number>,
}

export interface IProgramAllocationSummaryResult {
  results_type: string,
  program_id: string,
  output_schema_version: 'v1',
  hourly_results: {
    datetime: string,
    generation_mwh: number,
    program_allocation_by_subscription_id: Record<string, ISubscriptionAllocationResultDatum>,
  }[],
}


export interface IAllocationDiagnosticItem {
  level: 'debug' | 'info' | 'warning' | 'error' | 'success' | 'critical' | 'fatal'
  family: string
  summary: string
  detail: string
  timestamp: string
  data: Record<string, unknown> | null
  allocation_run_id: string
  id: string
  customer_id: string
}
