import { IFetchAggregatedEventsArgs } from "amp/api/assetEvents";
import { IForecast, IForecastData, useFetchForecastForAssetQuery } from "amp/api/assetForecasts";
import { IListGeneratorsArgs, useFetchGeneratorQuery, usePaginateGeneratorsQuery } from "amp/api/generators";
import { getViewingOpCoId } from "amp/store/ui/selectors";
import { AxiosResponse } from "axios";
import { difference } from "ramda";
import { useEffect, useState } from "react";
import { API } from "shared/api";
import { getOpcos } from "shared/store/user/selectors";
import { AggregationTypes, IAggregatedAssetEvent } from "shared/types/aggregatedEvents";
import { IGenerator } from "shared/types/generator";
import { useAppDispatch, useAppSelector } from "store";
import { getAssignmentsForGeneratorId, getGeneratorById, getGeneratorsById, getUtilityInventoryPagination, getUtilityInventorySummary, getUtilityInventorySummaryError, getUtilityInventorySummaryLoading, getUtilitySourceFacets, getUtilitySourceFacetsError, getUtilitySourceFacetsLoading, getUtilitySources, getUtilitySourcesPageError, getUtilitySourcesPageLoading, getUtilitySourcesPagination, shouldFetchUtilityInventorySummary, shouldFetchUtilitySourceFacets, shouldFetchUtilitysourcesPage } from "./selectors";
import { fetchUtilityInventorySummary, fetchUtilitySourceFacets, fetchUtilitySources, receiveGeneratorAssignments, receiveGenerators } from "./slice";

export const useGenerator = (generatorId: string, customerId?: string, skip?: boolean) => {

  const oci = useAppSelector(getViewingOpCoId);
  const res = useFetchGeneratorQuery({id: generatorId, customerId: customerId || oci}, { skip: !generatorId || skip});
  const dispatch = useAppDispatch();
  const gen = useAppSelector(s => getGeneratorById(s, generatorId));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveGenerators([res.data.generator]));
      dispatch(receiveGeneratorAssignments({assignments: res.data.assignments, generatorId}));
    }
  }, [res.data, dispatch, generatorId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: (gen as IGenerator | undefined),
  }
};

export const useGeneratorsPage = ({ page, perPage, nameSearch, fuelCategory, usState }: IListGeneratorsArgs) => {
  const oci = useAppSelector(getViewingOpCoId);
  const res = usePaginateGeneratorsQuery({ page, perPage, nameSearch, fuelCategory, usState, customerId: oci });
  const dispatch = useAppDispatch();
  const generatorIds = res.data?.data.map(({ id }) => id) || [];
  const generators = useAppSelector(s => getGeneratorsById(s, generatorIds));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveGenerators(res.data.data));
    }
  }, [res.data, dispatch, page, perPage]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: generators,
    pagination: res.data?.meta.pagination,
  }
}


export const useGeneratorAssignments = (generatorId: string) => {
  const oci = useAppSelector(getViewingOpCoId);
  const res = useFetchGeneratorQuery({id: generatorId, customerId: oci});
  const dispatch = useAppDispatch();
  const assignments = useAppSelector(s => getAssignmentsForGeneratorId(s, generatorId));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveGenerators([res.data.generator]));
      dispatch(receiveGeneratorAssignments({assignments: res.data.assignments, generatorId}));
    }
  }, [res.data, dispatch, generatorId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: assignments,
  }

}

export const useGeneratorForecast = (generatorId: string, year: number) => {
  const oci = useAppSelector(getViewingOpCoId);
  const forecastRes = useFetchForecastForAssetQuery({asset_id: generatorId, year, customerId: oci}, {skip: !generatorId});
  const [forecastData, setForecastData] = useState<IForecastData[]>();
  const [resultsLoading, setResultsLoading] = useState(true);

  useEffect(() => {
    const forecastBatch = forecastRes.data?.event_forecast_batch
    if (forecastBatch && !forecastData) {
      API.get<{results: IForecastData[]}>(`/${forecastBatch.customer_id}/forecast-data/${forecastBatch.id}/output.json`, {withCredentials: true})
        .then((res) => {
          setForecastData(res.data.results);
        })
        .catch((err) => {
          console.warn('TODO: capture error', err);
        })
        .finally(() => setResultsLoading(false));
    }
  }, [forecastRes.data, forecastData]);

  return {
    ...forecastRes,
    isLoading: forecastRes.isLoading || resultsLoading,
    isFetching: forecastRes.isFetching || resultsLoading,
    isFirstTimeLoading: forecastRes.isLoading || resultsLoading,
    isRefreshingData: forecastRes.isFetching || resultsLoading,
    data: forecastData,
  }
}

type OutputResults = {generatorId: string, response: Promise<AxiosResponse<{results: IForecastData[]}>>}[];

export const useGeneratorsForecast = (generatorIds: string[], years: number[]) => {
  const oci = useAppSelector(getViewingOpCoId);
  // TODO: perhaps aggregating forecasts like this should be done on the backend, not the frontend...
  const [loadingBatches, setLoadingBatches] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);
  const [error, setError] = useState(false);
  const [outputData, setOutputData] = useState<IForecastData[]>();
  const [nonGroupedOutputData, setNonGroupedOutputData] = useState<Record<string, IForecastData[]>>();
  const [outputResultsPromises, setOutputResultPromises] = useState<OutputResults>();
  const [fetchedGenIds, setFetchedGenIds] = useState<string[]>([]);

  useEffect(() => {
    if (!loadingBatches && !loadingResults && !error && !outputResultsPromises) {
      setFetchedGenIds(generatorIds);
      setLoadingBatches(true);
      const urls: string[] = [];
      generatorIds.forEach(genId => {
        years.forEach(year => {
          urls.push(
            `/api/v2/assets/${genId}/forecast?year=${year}&customer_id=${oci}`
          );
        });
      });
      const promises = urls.map(url => API.get<{event_forecast_batch: IForecast}>(url, {withCredentials: true}));
      Promise.all(promises).then((results) => {
        setOutputResultPromises(results.map(res => {
          if (res.status !== 200) {
            // TODO: report the failed requests to Sentry - we should know about the missing forecasts
            return undefined;
          }
          const forecast = res.data.event_forecast_batch;
          const generatorId = res.data.event_forecast_batch.asset_id;
          return {
            generatorId,
            response: API.get<{results: IForecastData[]}>(`/${forecast.customer_id}/forecast-data/${forecast.id}/output.json`, {withCredentials: true})
          };
        }).filter(res => !!res) as {generatorId: string, response: Promise<AxiosResponse<{results: IForecastData[]}, any>>}[]);
        setLoadingBatches(false);
      }).catch((err) => {
        setError(true);
        setLoadingBatches(false);
        setLoadingResults(false);
      });
    }
  }, [loadingBatches, error, outputResultsPromises, generatorIds, years, loadingResults, oci]);

  // wipe the output.json request promises if the generatorIds changes
  useEffect(() => {
    const diffLtr = difference(generatorIds, fetchedGenIds);
    const diffRtl = difference(fetchedGenIds, generatorIds);
    if (diffLtr.length !== 0 || diffRtl.length !== 0) {
      setOutputResultPromises(undefined);
      setOutputData(undefined);
      setNonGroupedOutputData(undefined);
      setError(false);
    }
  }, [generatorIds, fetchedGenIds]);

  useEffect(() => {
    if (outputResultsPromises?.length && !outputData && !loadingBatches && !loadingResults && !error) {
      setLoadingResults(true);
      const finalDataByStartDate: Record<string, number> = {};
      const finalDataByGeneratorId: Record<string, IForecastData[]> = {};
      Promise.all(outputResultsPromises.map(({response}) => response)).then((res) => {
        if (res) {
          res.forEach(({data}, idx) => {
            const genId = outputResultsPromises[idx].generatorId;
            data.results.forEach(({start_date, y_axis_value_wh}) => {
              if (finalDataByStartDate[start_date]) {
                finalDataByStartDate[start_date] += y_axis_value_wh;
              } else {
                finalDataByStartDate[start_date] = y_axis_value_wh;
              }
            });
            if (!finalDataByGeneratorId[genId]) {
              finalDataByGeneratorId[genId] = data.results;
            } else {
              finalDataByGeneratorId[genId] = finalDataByGeneratorId[genId].concat(data.results);
            }
          });
        }
        const sorted = Object.entries(finalDataByStartDate)
          .sort((x1, x2) => new Date(x1[0]).valueOf() - new Date(x2[0]).valueOf());
        const formatted = sorted.map(([start_date, y_axis_value_wh]) => ({start_date, y_axis_value_wh}));
        setOutputData(formatted);
        setNonGroupedOutputData(finalDataByGeneratorId);
        setLoadingResults(false);
      }).catch(() => {
        setError(true);
        setLoadingResults(false);
      });
    }
  }, [outputResultsPromises, loadingBatches, loadingResults, error, outputData]);


  return {
    loading: loadingBatches || loadingResults,
    error,
    data: outputData,
    nonGroupedData: nonGroupedOutputData,
  }
};


export const useUtilitySources = ({ page, perPage, usState, nameSearch, fuelCategory, assetTypes }: IListGeneratorsArgs) => {
  const dispatch = useAppDispatch();
  const opcoId = useAppSelector(getViewingOpCoId);
  const opcos = useAppSelector(getOpcos);

  const customerIds = opcoId ? [opcoId] : opcos.map(d => d.id);
  const oci = customerIds.sort().join(',');

  const sortedAssetTypes = assetTypes?.sort() || [];
  const serializedArgs = JSON.stringify({page, perPage, usState, nameSearch, fuelCategory, assetTypes: sortedAssetTypes});

  const shouldFetch = useAppSelector(s => shouldFetchUtilitysourcesPage(s, oci, serializedArgs))

  if (shouldFetch) {
    dispatch(fetchUtilitySources({page, perPage, usState, nameSearch, fuelCategory, assetTypes: sortedAssetTypes}));
  }
  const isLoading = useAppSelector(s => getUtilitySourcesPageLoading(s, oci));
  const isError = useAppSelector(s => getUtilitySourcesPageError(s, oci));
  const data = useAppSelector(s => getUtilitySources(s, oci));
  const pagination = useAppSelector(s => getUtilitySourcesPagination(s, oci));

  return {
    isLoading,
    isError,
    data,
    pagination,
  }
};

export const useUtilitySourceFacets = () => {
  const dispatch = useAppDispatch();
  const opcoId = useAppSelector(getViewingOpCoId);
  const opcos = useAppSelector(getOpcos);

  const customerIds = opcoId ? [opcoId] : opcos.map(d => d.id);
  const oci = customerIds.sort().join(',');

  const shouldFetch = useAppSelector(s => shouldFetchUtilitySourceFacets(s, oci, null))

  if (shouldFetch) {
    dispatch(fetchUtilitySourceFacets());
  }
  const isLoading = useAppSelector(s => getUtilitySourceFacetsLoading(s, oci));
  const isError = useAppSelector(s => getUtilitySourceFacetsError(s, oci));
  const data = useAppSelector(s => getUtilitySourceFacets(s, oci));

  return {
    isLoading,
    isError,
    data,
  }
};


export const useUtilityInventorySummary = <T>({ startDate, endDate, aggregationType, resolution }: IFetchAggregatedEventsArgs) => {
  const dispatch = useAppDispatch();
  const opcoId = useAppSelector(getViewingOpCoId);
  const opcos = useAppSelector(getOpcos);

  const customerIds = opcoId ? [opcoId] : opcos.map(d => d.id);
  const oci = customerIds.sort().join(',');

  const serializedArgs = JSON.stringify({startDate, endDate, aggregationType, resolution});

  const shouldFetch = useAppSelector(s => shouldFetchUtilityInventorySummary(s, oci, aggregationType, serializedArgs))

  if (shouldFetch) {
    dispatch(fetchUtilityInventorySummary({startDate, endDate, aggregationType, resolution, skipSourcesCount: aggregationType !== AggregationTypes.GENERATION_BY_FUEL}));
  }
  const isLoading = useAppSelector(s => getUtilityInventorySummaryLoading(s, oci, aggregationType));
  const isError = useAppSelector(s => getUtilityInventorySummaryError(s, oci, aggregationType));
  const data = useAppSelector(s => getUtilityInventorySummary(s, oci, aggregationType));
  const pagination = useAppSelector(s => getUtilityInventoryPagination(s, oci, aggregationType));

  return {
    isLoading,
    isError,
    data: data as null | IAggregatedAssetEvent<T>[],
    pagination,
  }
}