import { dataToDisplayValues, GenerationByFuelAggEventData, IAggregatedAssetEvent, IEmissionsFromGenerationData } from "shared/types/aggregatedEvents";
import { fuelDisplayName, GeneratorFuelCategories, GenFuelCategoriesOrder } from "shared/types/generator";
import { MeasurementDisplayPreference } from "shared/types/user";
import { getColorForFuel } from "shared/utils/color";


export const makeGenerationTypePieChartOptions = (totalGenerationByFuel: Record<string, number>) => {
  return {
    chart: {
      type: 'pie',
      height: 300,
    },
    lang: {
      noData: 'No generation found',
    },
    legend: {
      enabled: true,
      alignColumns: false,
      padding: 0,
      margin: 0,
    },
    plotOptions: {
      pie: {
        innerSize: '40%',
        borderWidth: 3,
        borderRadius: 8,
        borderColor: '#fff',
        dataLabels: { enabled: false },
        tooltip: {
          pointFormatter: function () {
            const formattedNum = this.y?.toLocaleString() || '';
            return `<span style="color:${this.color}">●</span> <b>${formattedNum}</b> MWh`;
          },
        }
      }
    },
    series: [{
      type: 'pie',
      showInLegend: true,
      data: Object.entries(totalGenerationByFuel)
        .sort((a, b) => {
          const firstFuel = a[0] as keyof typeof GenFuelCategoriesOrder;
          const secondFuel = b[0] as keyof typeof GenFuelCategoriesOrder;
          return GenFuelCategoriesOrder[secondFuel] - GenFuelCategoriesOrder[firstFuel];
        }).map(([fuelType, gen]) => {
          return {
            y: Math.round(gen / 1_000_000),
            name: fuelDisplayName(fuelType),
            color: getColorForFuel(GeneratorFuelCategories[fuelType as keyof typeof GeneratorFuelCategories]),
          };
        }),
    }],
  } as Highcharts.Options;
};

export const makeEmissionsChartData = (emissionsResData?: IAggregatedAssetEvent<IEmissionsFromGenerationData>[] | null, measurement?: MeasurementDisplayPreference) => {
  if (!emissionsResData) return {emissionTotalSeries:[], emissionRateSeries:[], totalEmissionsCo2e:0 };

  const emissionsSeriesData: number[][] = [];
  const emissionRateSeriesData: number[][] = [];

  const isMetric = measurement === MeasurementDisplayPreference.METRIC
  const lbToTon = isMetric ? 2_204.62 : 2_000;
  const lbConversionFactor = isMetric ? 2.20462 : 1;

  let totalEmissionsLbsCo2e = 0;
  emissionsResData.forEach((event) => {
    const eventData = event.data;

    totalEmissionsLbsCo2e += eventData.sum_co2e_mass_lb;
    emissionsSeriesData.push([new Date(event.start_date).valueOf(), Math.round(eventData.sum_co2e_mass_lb / lbToTon)]);
    emissionRateSeriesData.push([new Date(event.start_date).valueOf(), Math.round(eventData.mean_co2e_rate_lb_per_mwh / lbConversionFactor)]);
  });

  return { emissionTotalSeries: emissionsSeriesData, emissionRateSeries: emissionRateSeriesData, totalEmissionsCo2e: totalEmissionsLbsCo2e };
}

export const makeEmissionsChartOptions = (emissionsResData?: IAggregatedAssetEvent<IEmissionsFromGenerationData>[] | null, measurement?: MeasurementDisplayPreference, mouseOver?: Function) => {
  const isMetric = measurement === MeasurementDisplayPreference.METRIC
  const emissionsData = makeEmissionsChartData(emissionsResData, measurement);
  const co2EmissionsTonsSeriesData = emissionsData.emissionTotalSeries;
  const co2RateLbsPerMWhSeriesData = emissionsData.emissionRateSeries;
  return {
    series: [
      {
        name: `Total Carbon Emissions (${isMetric ? 'metric ' : ''}tons of CO<sub>2</sub>e)`,
        data: co2EmissionsTonsSeriesData,
        type: 'area',
        color: '#4E566D',
      },
      {
        name: `Generated Carbon Intensity (${isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e/MWh)`,
        data: co2RateLbsPerMWhSeriesData,
        type: 'line',
        color: '#7FAAD1',
        yAxis: 1,
      }
    ],
    legend: {
      enabled: true,
      useHTML: true,
    },
    lang: {
      noData: 'No generation found for the selected time range',
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(180, 180, 180, 0.5)'], // start
            [1, 'rgba(180, 180, 180, 0)'] // end
          ]
        },
      },
      series: {
        point: {
          events: {
            mouseOver,
          }
        }
      },
    },
    xAxis: {
      crosshair: true,
    },
    yAxis: [
      {
        title: {
          text: `<span style="color: #4E566D">\u25CF</span> ${isMetric ? 'm' : ''}Tons of CO<sub>2</sub>e`,
          useHTML: true,
        },
        min: 0,
      },
      {
        opposite: true,
        title: {
          text: `<span style="color: #7FAAD1">\u25CF</span> CO<sub>2</sub>e ${isMetric ? 'kgs' : 'lbs'}/MWh`,
          useHTML: true,
        },
        min: 0,
      }
    ]
  } as Highcharts.Options;
};

export const makeGenerationChartData = (genData?: IAggregatedAssetEvent<GenerationByFuelAggEventData>[] | null) => {
  if (!genData) return {totalGenerationWh: 0, totalGenerationByFuel: {}, aggregatedSeriesByFuel: {}};

  let totalGen = 0;
  const eventsByFuelType: Record<string, { x: number, y: number }[]> = {};
  const totalsByFuel: Record<string, number> = {};
  genData.forEach((aggregatedEvent: IAggregatedAssetEvent) => {
    const clientEvent = dataToDisplayValues(aggregatedEvent);
    const genData = clientEvent.data;
    Object.entries(genData).forEach(([fuelType, aggData]) => {
      totalGen += aggData.sum_generated_wh;
      if (totalsByFuel[fuelType]) {
        totalsByFuel[fuelType] += aggData.sum_generated_wh;
      } else {
        totalsByFuel[fuelType] = aggData.sum_generated_wh;
      }
      const generationChartPoint = {
        x: new Date(aggregatedEvent.start_date).getTime(),
        // TODO is GWh the right units on this?
        // The generation summary chart has units of GWh
        y: aggData.sum_generated_wh / 1_000_000_000
      };
      if (eventsByFuelType[fuelType]) {
        eventsByFuelType[fuelType].push(generationChartPoint);
      } else {
        eventsByFuelType[fuelType] = [generationChartPoint];
      }
    });
  });

  return {totalGenerationWh: totalGen, totalGenerationByFuel: totalsByFuel, aggregatedSeriesByFuel: eventsByFuelType};
}

export const makeGenerationChartOptions = (genData: Record<string, {x: number, y: number}[]>, mouseOver?: Function) => {
  return {
    series: Object.entries(genData)
      .sort((a, b) => {
        const firstFuel = a[0] as keyof typeof GenFuelCategoriesOrder;
        const secondFuel = b[0] as keyof typeof GenFuelCategoriesOrder;
        return GenFuelCategoriesOrder[secondFuel] - GenFuelCategoriesOrder[firstFuel];
      }).map(([fuelType, generationList]) => {
        return {
          type: 'column',
          stacking: 'normal',
          name: fuelDisplayName(fuelType),
          color: getColorForFuel(GeneratorFuelCategories[fuelType as keyof typeof GeneratorFuelCategories]),
          data: generationList,
        };
      }),
    legend: {
      enabled: true,
    },
    lang: {
      noData: 'No generation found for the selected time range',
    },
    xAxis: {
      crosshair: true,
    },
    plotOptions: {
      column: {
        tooltip: {
          valueSuffix: ' GWh',
          valueDecimals: 1,
        },
      },
      series: {
        point: {
          events: {
            mouseOver,
          }
        }
      }
    },
  } as Highcharts.Options;
};