import { Button, Group, InputLabel, InputWrapper, Modal, TextInput } from "@mantine/core";
import { useAdminPatchCustomerMutation } from "admin/api/customers";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { ICustomer } from "shared/types/customer";


const RenameCustomerModal = ({
  onClose,
  isOpen,
  customer,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, customer: ICustomer}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [put] = useAdminPatchCustomerMutation();
  const defaultName = useMemo(() => customer.name || '', [customer]);
  const [name, setName] = useState(defaultName);

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!customer) {
      return
    }
    try {
      await put({
        customerId: customer.id,
        body: {
          name: name,
          status: customer.status,
          parent_id: customer.parent_id,
          customer_type: customer.customer_type,
        },
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setName('');
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Edit this customer's name"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The name of the customer</InputLabel>
          <TextInput value={name} onChange={(e) => setName(e.target.value)}/>
        </InputWrapper>

        <Group justify="flex-end" mt="md">
          <Button disabled={!name.trim()} loading={isLoading} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default RenameCustomerModal;