import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { store } from 'store';
import { theme } from 'theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ENV_NAME, LOCAL, SENTRY_DSN } from './shared/constants/resources';

import './index.css';

// @ts-ignore
if (module.hot) {
  // necessary due to using a different hostname in the local environment
  // @ts-ignore
  module.hot.accept();
}

const mountSentry = () => {
  if (!LOCAL) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENV_NAME,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        })
      ],
      normalizeDepth: 10,
      beforeBreadcrumb(breadcrumb) {
        const length = JSON.stringify(breadcrumb).length;
        if (length > 5000) {
          breadcrumb.data = undefined;
        }
        return breadcrumb;
      },
    });
  }
};
setTimeout(mountSentry, 0);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <MantineProvider theme={theme}>
      <Notifications />
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </MantineProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
