import { Group, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";

import { useAdminFetchCustomerReportQuery } from "admin/api/customerReports";
import AdminViewContainer from "admin/components/ViewContainer";
import { timestampToLongDate } from "shared/utils/dates";


const CustomerReportView = () => {
  const { reportId='' } = useParams<{reportId: string}>();
  const {
    data,
    isLoading,
    isError,
  } = useAdminFetchCustomerReportQuery(reportId, {skip: !reportId});

  if (isError) {
    return <div>Failed to load customer report</div>;
  }

  const report = data?.data;

  return <AdminViewContainer title={report?.name || reportId} actions={<div>actions to do things here</div>}>
    {(isLoading || !report) && <Loader />}
    {report && <div>
      <div>
        <Group>
          <div>ID</div>
          <div>{report.id}</div>
        </Group>
        <Group>
          <div>Name</div>
          <div>{report.name}</div>
        </Group>
        <Group>
          <div>Description</div>
          <div>{report.description}</div>
        </Group>
        <Group>
          <div>Status</div>
          <div>{report.status}</div>
        </Group>
        <Group>
          <div>Type</div>
          <div>{report.customer_report_type}</div>
        </Group>
        <Group>
          <div>Updated at</div>
          <div>{report.updated_at ? timestampToLongDate(report.updated_at) : <em>never updated</em>}</div>
        </Group>
        <Group>
          <div>Updated by</div>
          <div>{report.updated_by}</div>
        </Group>
        <Group>
          <div>Created at</div>
          <div>{timestampToLongDate(report.created_at)}</div>
        </Group>
        <Group>
          <div>Config</div>
          <pre>{JSON.stringify(report.config, undefined, 2)}</pre>
        </Group>
      </div>
    </div>}
  </AdminViewContainer>
};

export default CustomerReportView;
