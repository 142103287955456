import { createSelector } from "reselect";
import { Customer } from "shared/types/customer";
import { getThisYearEnd, nMinutesAgo } from "shared/utils/dates";
import { RootState } from "store";

const root = (state: RootState) => state.customer;

const byId = createSelector(
  root,
  (state) => state.byId,
);

export const customersById = byId;

export const getCustomerById = createSelector(
  byId,
  (_: RootState, customerId: string) => customerId,
  (custById, customerId) => {
    const cust = custById[customerId];
    if (cust) {
      return new Customer(cust);
    }
    return cust;
  }
);

export const getCustomersById = createSelector(
  byId,
  (_: RootState, customerIds: string[]) => customerIds,
  (custsById, customerIds) => customerIds.map(c => custsById[c]).filter(x => !!x)
);

export const getMaxAllocationEndDateForCustomer = createSelector(
  byId,
  (_: RootState, customerId: string) => customerId,
  (custsById, customerId) => {
    const customer = custsById[customerId];
    if (!customer) {
      return getThisYearEnd();
    }

    const latestGenStr = customer.attributes.find(a => a.name === 'latest_amp_generation_date')?.value as string;
    const latestLoadStr = customer.attributes.find(a => a.name === 'latest_amp_consumption_date')?.value as string;
    const latestGenDate = latestGenStr ? new Date(latestGenStr) : null;
    const latestLoadDate = latestLoadStr ? new Date(latestLoadStr) : null;
    const isGenLatest = (latestGenDate && latestLoadDate) && latestGenDate > latestLoadDate;
    const latestDate = isGenLatest ? latestLoadDate : latestGenDate;
    const maxAllocationEndDate = (latestGenDate && latestLoadDate && latestDate) ? latestDate : getThisYearEnd();
    return maxAllocationEndDate;
  }
);

const customerPageResponseByOci = createSelector(
  root,
  (state) => state.customersPageResponseByOCI
);

const getCustomerPageResponseByOci = createSelector(
  customerPageResponseByOci,
  (_: RootState, oci: string) => oci,
  (pageResponseByOci, oci) => {
    const ociRes = pageResponseByOci[oci];
    if (!ociRes) {
      return {
        data: null,
        fetchFailed: false,
        isFetching: false,
        lastReceived: null,
      }
    } else {
      return ociRes;
    }
  }
);

export const getUtilityCustomerPageLoading = createSelector(
  getCustomerPageResponseByOci,
  ociRes => ociRes.isFetching,
);

export const getUtilityCustomers = createSelector(
  byId,
  getCustomerPageResponseByOci,
  (custsById, ociRes) => ociRes.data?.map(cid => custsById[cid]) || []
);

export const getUtilityCustomerPageError = createSelector(
  getCustomerPageResponseByOci,
  ociRes => ociRes.fetchFailed,
);

export const shouldFetchUtilityCustomerPage = createSelector(
  getCustomerPageResponseByOci,
  (ociRes) => {
    if (!ociRes) {
      return true;
    }
    const isExpired = ociRes.lastReceived === null || ociRes.lastReceived < nMinutesAgo(5);
    if (isExpired && !ociRes.isFetching && !ociRes.fetchFailed) {
      return true
    }
    return false;
  }
)