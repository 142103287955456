import { Group, Select, Stack, Switch, Tabs, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';

import { getViewingCustomerType, getViewingOpCoId } from 'amp/store/ui/selectors';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { AssetEventResolution } from 'shared/types/assetEvents';
import { CustomerType } from 'shared/types/customer';
import { getLastYearStart, getThisYearEnd } from 'shared/utils/dates';
import { useAppSelector } from 'store';
import CustomerSelection from './customerSelection';
import GeneratorSelection from './generatorSelection';
import ProjectionsChart from './projectionsChart';
import './style.css';

const ONE_MONTH_MILLIS = 1000 * 60 * 60 * 24 * 31;

const ProjectionsView = () => {
  const [params, setParams] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);
  const isAggregating = params.get('ag');
  const showForecasts = params.get('f');
  const startDateStr = params.get('s') || getLastYearStart();
  const endDateStr = params.get('e') || getThisYearEnd();
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const viewingCustomerType = useAppSelector(getViewingCustomerType);

  let resolution = params.get('r') || '1d';
  const isHourlyDisabled = Math.abs(startDate.valueOf() - endDate.valueOf()) > ONE_MONTH_MILLIS;

  const resolutionOptions = [
    { label: `Hourly${isHourlyDisabled ? ' (1 month max)' : ''}`, value: AssetEventResolution.HOUR, disabled: isHourlyDisabled },
    { label: 'Daily', value: AssetEventResolution.DAY },
    { label: 'Monthly', value: AssetEventResolution.MONTH },
  ];

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  const onAggregationToggle = (newChecked: boolean) => {
    if (!newChecked) {
      setParams(newParams => {
        newParams.delete('ag');
        return newParams;
      });
    } else {
      onParamsChange([{ name: 'ag', value: 'true' }]);
    }
  }

  const onShowForecastsToggle = (newChecked: boolean) => {
    if (!newChecked) {
      setParams(newParams => {
        newParams.delete('f');
        return newParams;
      });
    } else {
      onParamsChange([{ name: 'f', value: 'true' }]);
    }
  }

  if (isHourlyDisabled && resolution === '1h') {
    resolution = '1d';
    onParamsChange([{ name: 'r', value: '1d' }])
  }

  const mustSelectOpCo = viewingCustomerType === CustomerType.UTILITY && !oci;
  return (
    <div className="projections-page--scroll-area">
      {mustSelectOpCo && <BasePaper>
        Please select an operating company to begin
      </BasePaper>}
      {!mustSelectOpCo && <BasePaper
        titleContent={<div className="projections-page--title">Projections Analysis</div>}
        actions={<Group justify="flex-end">
          <Stack gap={4}>
            <Switch
              color="var(--color-blue-2)"
              size='xs'
              checked={!!showForecasts}
              onChange={() => onShowForecastsToggle(!showForecasts)}
            />
            <Text c="var(--color-blue-2)" fz={12}>Show Projections</Text>
          </Stack>
          <Stack gap={4}>
            <Switch
              size='xs'
              color="var(--color-blue-2)"
              checked={!!isAggregating}
              onChange={e => onAggregationToggle(e.currentTarget.checked)}
            />
            <Text c="var(--color-blue-2)" fz={12}>Aggregate</Text>
          </Stack>
          <Select
            value={resolution}
            allowDeselect={false}
            data={resolutionOptions}
            onChange={onResolutionChange}
            rightSection={<IconChevronDown size={20} />}
            w="176px"
          />
          <Group justify="flex-start">
            <UtcDatePicker
              value={startDate}
              isStartDate={true}
              maxDate={endDate || undefined}
            />
            -
            <UtcDatePicker
              value={endDate}
              minDate={startDate || undefined}
            />
          </Group>
        </Group>}
      >
        <ProjectionsChart />
        <Tabs defaultValue="generators">
          <Tabs.List>
            <Tabs.Tab value="generators">
              Generators
            </Tabs.Tab>
            <Tabs.Tab value="customers">
              Customers
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="generators">
            <GeneratorSelection />
          </Tabs.Panel>
          <Tabs.Panel value="customers">
            <CustomerSelection />
          </Tabs.Panel>
        </Tabs>
      </BasePaper>}
    </div>
  )
}

export default ProjectionsView;