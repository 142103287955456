import { Button, createTheme, Group, MantineProvider, Notification, Select, Stack, Switch, TextInput, Title, Transition } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useState } from 'react';

import { useCreateProgramMutation } from 'amp/api/programs';
import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import FeatureGate from 'shared/components/FeatureGate/featureGate';
import BasePaper from 'shared/components/Paper/basePaper';
import { ProgramCommitmentType, ProgramStatus } from 'shared/types/program';
import { getErrorMessagesFromApiError } from 'shared/utils/data';
import { useAppSelector } from 'store';
import './style.css';

const statusOptions = [
  { label: 'Active', value: ProgramStatus.ACTIVE },
  { label: 'Inactive', value: ProgramStatus.INACTIVE },
  // { label: 'Archived', value: ProgramStatus.ARCHIVED },
  // { label: 'Deleted', value: ProgramStatus.DELETED },
];

// This is the only way mantine recommends to do this
const cursorPointerTheme = createTheme({ cursorType: 'pointer' });

export default function CreateProgramView() {
  const navigate = useAmpNav();
  const oci = useAppSelector(getViewingOpCoId);
  const [create] = useCreateProgramMutation();
  const [submittedState, setSubmittedState] = useState<null | 'fail'>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [editedPeriod, setEditedPeriod] = useState<'annual' | 'hourly'>('hourly');
  const [editedStatus, setEditedStatus] = useState<ProgramStatus>(ProgramStatus.ACTIVE);
  const [editedCommitType, setEditedCommitType] = useState<ProgramCommitmentType>(ProgramCommitmentType.PERCENT_GENERATION);
  const [editedIsHypothetical, setEditedIsHypothetical] = useState<boolean>(true);
  const [errorsByField, setErrorsByField] = useState<Record<string, string[]>>({});

  const onSubmit = async () => {
    setErrorsByField({});
    setIsSaving(true);
    try {
      await create({
        customerId: oci,
        body: {
          priority: 1, // TODO: this field isn't used but is required by the backend
          name: editedName,
          status: editedStatus,
          is_hypothetical: editedIsHypothetical,
          data: {
            program_config: {
              generation_assignment: 'pooled', // TODO: this field isn't used but is required by the backend
              accounting_period: editedPeriod,
              commitment_type: editedCommitType,
            }
          }
        },
      }).unwrap();
      setIsSaving(false);
      navigate('/dashboard/programs');
    } catch (err) {
      const errMsgs = getErrorMessagesFromApiError(err);
      if (errMsgs) {
        setErrorsByField(errMsgs);
      }
      setIsSaving(false);
      setSubmittedState('fail');
    } finally {
      setTimeout(() => {
        setSubmittedState(null);
      }, 1800);
    }
  };

  const isSaveEnabled = !!editedName.trim();
  const dataErrors = errorsByField.data as unknown as Record<string, Record<string, string[]>>;
  const configErrors = dataErrors?.program_config;
  return <>
    <div className="program-create--title-container">
      <Title size="24px">New Program</Title>
      <Group>
        <AmpLink to="/dashboard/programs">
          <Button className="program-create--button">
            Cancel
          </Button>
        </AmpLink>
        <Button
          className={`program-edit--button ${isSaveEnabled ? '' : 'is-disabled'}`}
          onClick={onSubmit}
          disabled={!isSaveEnabled}
          loading={isSaving}
        >
          Save Changes
        </Button>
      </Group>
    </div>
    <div className="program-create--fields-container">
      <BasePaper>
        <Stack w="calc(50% - 30px)">
          <TextInput
            error={errorsByField.name && errorsByField.name[0]}
            placeholder="Program name"
            label="Name"
            value={editedName}
            onChange={e => setEditedName(e.target.value)}
          />
          <Select
            error={errorsByField.status && errorsByField.status[0]}
            placeholder="Program Status"
            label="Status"
            data={statusOptions}
            clearable={false}
            allowDeselect={false}
            value={editedStatus}
            onChange={e => setEditedStatus(e as ProgramStatus)}
          />
          <FeatureGate propertyName="amp_program_configuration_demo_access">
            {/* this property defaults to PERCENT_GENERATION if user doesn't have feature gate */}
            <Select
              label="Commitment Type"
              error={configErrors?.commitment_type && configErrors?.commitment_type[0]}
              data={[
                { label: 'Percentage of Generation', value: ProgramCommitmentType.PERCENT_GENERATION },
                { label: 'Percentage of Consumption', value: ProgramCommitmentType.PERCENT_CONSUMPTION },
                { label: 'Fixed MWh', value: ProgramCommitmentType.FIXED_COMMITMENT },
              ]}
              clearable={false}
              allowDeselect={false}
              value={editedCommitType}
              onChange={e => setEditedCommitType(e as ProgramCommitmentType)}
            />
          </FeatureGate>
          <Select
            error={configErrors?.accounting_period && configErrors?.accounting_period[0]}
            label="Accounting period"
            data={[{ label: 'Annual', value: 'annual' }, { label: 'Hourly', value: 'hourly' }]}
            clearable={false}
            allowDeselect={false}
            value={editedPeriod}
            onChange={e => setEditedPeriod(e as ('annual' | 'hourly'))}
          />
          <MantineProvider theme={cursorPointerTheme}>
            <Switch
              error={errorsByField.is_hypothetical && configErrors?.is_hypothetical[0]}
              label="Hypothetical Program"
              description="Hypothetical programs cannot be included in settleable allocation runs. Programs not given a status of Hypothetical must be included for an allocation to be settleable."
              checked={editedIsHypothetical === null ? true : editedIsHypothetical}
              onChange={e => setEditedIsHypothetical(e.target.checked)}
              color="var(--color-blue-2)"
            />
          </MantineProvider>
        </Stack>
      </BasePaper>
    </div>

    <Transition mounted={submittedState === 'fail'}>
      {(transitionStyle) => (
        <Notification
          onClose={() => setSubmittedState(null)}
          title="Error"
          icon={<IconX style={{ width: '20px', height: '20px' }} />}
          color="red"
          className="dashboard-global--notification-container"
          style={transitionStyle}
        >
          We failed to save your changes, please try again
        </Notification>
      )}
    </Transition>

  </>;
}