import { userAuthQueries } from "shared/api/queries";
import { IUserAuthPaginationResponse } from "shared/types/api";
import { CustomerPropertyNamespace, CustomerPropertyTypes, CustomerStatus, CustomerType, ICustomer, ICustomerExternalId, ICustomerParent, ICustomerSSOConfig, IParsedCustomerProperty } from "shared/types/customer";


interface IListCustomersArgs {
  page: number
  perPage: number
}

interface IUpsertPropertyPayload {
  property_value: string
  property_type: 'STRING' | 'JSON' | 'BOOLEAN' | 'NUMBER'
  property_namespace: 'NONE' | 'SETTINGS' | 'PRIVATE' | 'GATES'
  property_name: string
  customerId: string
}

interface IPatchCustomemrPayload {
  name: string
  parent_id?: string | null
  status: CustomerStatus
  customer_type?: CustomerType | null
}

interface ICreateCustomerPayload {
  name: string
  parent_id?: string | null
  status: CustomerStatus
  customer_type?: CustomerType
  attributes: {
    property_name: string
    property_value: string
    property_type: CustomerPropertyTypes
    property_namespace: CustomerPropertyNamespace
  }[]
}


const adminCustomersQueries = userAuthQueries.injectEndpoints({
  endpoints: (build) => ({
    adminPaginateCustomers: build.query<IUserAuthPaginationResponse<ICustomer>, IListCustomersArgs>({
      query: ({page, perPage}) => {
        const qs = new URLSearchParams();
        qs.set('offset', ((page - 1) * perPage).toString());
        qs.set('limit', perPage.toString());

        return {
          url: `/admin/customers/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [{type: 'Customer', id: 'PAGE'}],
    }),

    adminFetchCustomer: build.query<{customer: ICustomer}, string>({
      query: (id) => `/admin/customers/${id}`,
      providesTags: (res) => res ? [{type: 'Customer', id: res.customer.id}] : [],
    }),

    adminUpsertCustomerProperty: build.mutation<{properties: IParsedCustomerProperty}, IUpsertPropertyPayload>({
      query: ({customerId, ...rest}) => {
        return {
          url: `/admin/customers/${customerId}/properties`,
          method: 'POST',
          body: rest,
        }
      },

      invalidatesTags: (res, _, args) => res ? [{type: 'Customer', id: args.customerId}, {type: 'Customer', id: 'PAGE'}] : [],
    }),

    adminDeleteCustomerProperty: build.mutation<{ status: string }, { customerId: string, propertyName: string }>({
      query: ({customerId, propertyName }) => {
        return {
          url: `/admin/customers/${customerId}/properties/${propertyName}`,
          method: 'DELETE',
        }
      },

      invalidatesTags: (res, _, args) => res ? [{type: 'Customer', id: args.customerId}, {type: 'Customer', id: 'PAGE'}] : [],
    }),

    adminPatchCustomer: build.mutation<{customer: ICustomer}, {customerId: string, body: IPatchCustomemrPayload}>({
      query: ({customerId, body}) => ({
        url: `/admin/customers/${customerId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (res) => res ? [{type: 'Customer', id: 'PAGE'}, {type: 'Customer', id: res.customer.id}] : [],
    }),

    adminPaginateCustomerExternalIds: build.query<{data: ICustomerExternalId[]}, string>({
      query: (customerId) => {
        return {
          url: `/admin/customers/external-ids/list?customer_id=${customerId}`,
          method: 'GET',
        }
      },

      providesTags: [{type: 'CustomerExternalId', id: 'PAGE'}],
    }),

    adminPaginateSSOConfigs: build.query<IUserAuthPaginationResponse<ICustomerSSOConfig>, string>({
      query: (customerId) => `/admin/customers/${customerId}/sso-configs`,
      providesTags: [{type: 'CustomerSSOConfig', id: 'PAGE'}],
    }),

    adminCreateSSOConfig: build.mutation<{data: ICustomerSSOConfig}, Partial<ICustomerSSOConfig>>({
      query: (body) => ({
        url: '/admin/customers/sso-configs/',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{type: 'CustomerSSOConfig', id: 'PAGE'}],
    }),

    adminPatchSSOConfig: build.mutation<{data: ICustomerSSOConfig}, Partial<ICustomerSSOConfig>>({
      query: (body) => ({
        url: `/admin/customers/sso-configs/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => [{type: 'CustomerSSOConfig', id: 'PAGE'}],
    }),

    adminBulkFetchCustomers: build.query<{customers: ICustomer[]}, {customerIds: string[]}>({
      query: ({customerIds}) => {
        const qs = new URLSearchParams();
        customerIds.forEach(customerId => {
          qs.append('customer_id', customerId);
        });
        return {
          url: `/admin/customers/bulk-fetch?${qs}`,
        }
      },

      providesTags: res => res ? res.customers.map(({id}) => ({type: 'Customer', id })) : [],
    }),

    adminCreateCustomer: build.mutation<{customer: ICustomer}, ICreateCustomerPayload>({
      query: (body) => ({
        url: '/admin/customers/',
        method: 'POST',
        body,
      }),

      invalidatesTags: res => res ? [{type: 'Customer', id: 'PAGE'}] : [],
    }),

    adminPaginateParents: build.query<IUserAuthPaginationResponse<ICustomerParent>, {customerId: string, page: number, perPage: number}>({
      query: ({page, perPage, customerId}) => {
        const qs = new URLSearchParams();
        qs.set('offset', ((page - 1) * perPage).toString());
        qs.set('limit', perPage.toString());

        return {
          url: `/admin/customers/${customerId}/parents?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [{type: 'CustomerParent', id: 'PAGE'}],
    }),

    adminPaginateChildren: build.query<IUserAuthPaginationResponse<ICustomerParent>, {customerId: string, page: number, perPage: number}>({
      query: ({page, perPage, customerId}) => {
        const qs = new URLSearchParams();
        qs.set('offset', ((page - 1) * perPage).toString());
        qs.set('limit', perPage.toString());

        return {
          url: `/admin/customers/${customerId}/children?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [{type: 'CustomerParent', id: 'PAGE'}],
    }),
  }),
  overrideExisting: false,
});


export const {
  useAdminFetchCustomerQuery,
  useAdminPaginateCustomersQuery,
  useAdminUpsertCustomerPropertyMutation,
  useAdminDeleteCustomerPropertyMutation,
  useAdminPatchCustomerMutation,
  useAdminPaginateCustomerExternalIdsQuery,
  useLazyAdminFetchCustomerQuery,
  useAdminPaginateSSOConfigsQuery,
  useAdminCreateSSOConfigMutation,
  useAdminPatchSSOConfigMutation,
  useAdminBulkFetchCustomersQuery,
  useAdminCreateCustomerMutation,
  useAdminPaginateParentsQuery,
  useAdminPaginateChildrenQuery,
} = adminCustomersQueries;