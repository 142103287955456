import { useAdminPaginateParentsQuery } from "admin/api/customers";
import AdminViewContainer from "admin/components/ViewContainer";
import { useAmpNav } from "amp/hooks";
import { useParams, useSearchParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";


const tableColumns = [
  {
    key: 'name',
    displayValue: 'Name',
  },
  {
    key: 'id',
    displayValue: 'ID',
  },
  {
    key: 'customerType',
    displayValue: 'Customer Type',
  },
]

const CustomerParentsView = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const [params, setSearchParams] = useSearchParams();
  const nav = useAmpNav();

  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminPaginateParentsQuery({
    page,
    perPage,
    customerId,
  });

  const onPageChange = (newPage: number) => {
    setSearchParams(newParams => {
      newParams.set('p', newPage.toString());
      return newParams;
    });
  };

  const onClickRow = (customerId: string) => {
    nav(`/admin/customers/${customerId}`);
  };

  const pagination = data?.meta.pagination;
  const customers = data?.data || [];

  return <AdminViewContainer title="Parent Customers">
    <BaseTable
      rows={customers.map(customer => ({
        id: customer.parent_customer_id,
        name: customer.parent_customer_name,
        customerType: customer.parent_customer_type || <em>Unspecified</em>
      }))}
      onTableRowClicked={onClickRow}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={pagination?.last || 0}
      totalItems={pagination?.total_items || 0}
      currentPage={page}
      onPageChange={onPageChange}
    />
  </AdminViewContainer>
};

export default CustomerParentsView;