import type { CheckboxProps } from '@mantine/core';
import { Checkbox, createTheme, MantineProvider } from '@mantine/core';

// This is the only way mantine recommends to do this
const checkboxTheme = createTheme({ cursorType: 'pointer' });

const BaseCheckbox = (props: CheckboxProps) => {
  return (
    <MantineProvider theme={checkboxTheme}>
      <Checkbox {...props} />
    </MantineProvider>
  );
}

export default BaseCheckbox;