import { useAdminPaginateCustomerReportsQuery } from "admin/api/customerReports";
import AdminViewContainer from "admin/components/ViewContainer";
import { useAmpNav } from "amp/hooks";
import { useParams, useSearchParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";


const tableColumns = [
  {
    key: 'name',
    displayValue: 'Name',
  },
  {
    key: 'id',
    displayValue: 'ID',
  },
  {
    key: 'description',
    displayValue: 'Description',
  },
  {
    key: 'status',
    displayValue: 'Status',
  },
  {
    key: 'type',
    displayValue: 'Type',
  }
]

const CustomerReportsView = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const [params, setSearchParams] = useSearchParams();
  const nav = useAmpNav();

  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminPaginateCustomerReportsQuery({
    page,
    perPage,
    customerId,
  });

  const onPageChange = (newPage: number) => {
    setSearchParams(newParams => {
      newParams.set('p', newPage.toString());
      return newParams;
    });
  };

  const onClickRow = (reportId: string) => {
    nav(`/admin/customers/${customerId}/customer-reports/${reportId}`);
  };

  const pagination = data?.meta.pagination;
  const reports = data?.data || [];

  return <AdminViewContainer title="Customer Reports">
    <BaseTable
      rows={reports.map(report => ({
        id: report.id,
        name: report.name,
        description: report.description,
        status: report.status,
        type: report.customer_report_type,
      }))}
      onTableRowClicked={onClickRow}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={pagination?.last || 0}
      totalItems={pagination?.total_items || 0}
      currentPage={page}
      onPageChange={onPageChange}
    />
  </AdminViewContainer>
};

export default CustomerReportsView;