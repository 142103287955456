import { Button, Group, InputDescription, InputLabel, InputWrapper, Loader, Modal, TextInput } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useAdminPatchCustomerMutation, useLazyAdminFetchCustomerQuery } from "admin/api/customers";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { ICustomer } from "shared/types/customer";


const EditCustomerParentModal = ({
  onClose,
  isOpen,
  customer,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, customer: ICustomer}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [put] = useAdminPatchCustomerMutation();
  const [doesCustomerExist, setDoesCustomerExist] = useState(false);
  const defaultParentId = useMemo(() => customer.parent_id || '', [customer]);
  const [parentId, setParentId] = useState(defaultParentId);

  const [fetchCustomer, fetchCustomerRes] = useLazyAdminFetchCustomerQuery();

  const checkDoesCustomerExist = async () => {
    if (!parentId) {
      if (doesCustomerExist) {
        setDoesCustomerExist(false);
      }
      return
    }

    try {
      await fetchCustomer(parentId).unwrap();
      setDoesCustomerExist(true);
    } catch (err) {
      setDoesCustomerExist(false);
    }
  }


  useEffect(() => {
    setParentId(defaultParentId);
  }, [defaultParentId]);

  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!customer) {
      return
    }
    try {
      await put({
        customerId: customer.id,
        body: {
          name: customer.name,
          status: customer.status,
          parent_id: parentId,
          customer_type: customer.customer_type,
        },
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setParentId('');
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Edit this customer's parent ID"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The ID of the parent customer</InputLabel>
          <InputDescription>Be careful to make sure that it is correct. This could leak data if done incorrectly.</InputDescription>
          <TextInput value={parentId} onChange={(e) => setParentId(e.target.value)} onBlur={checkDoesCustomerExist} />
          {fetchCustomerRes.isLoading && <InputDescription><Loader size={8}/> Checking that customer exists</InputDescription>}
          {!fetchCustomerRes.isLoading && doesCustomerExist && <InputDescription><IconCheck color="var(--color-teal-6)" size={8} /> Customer exists</InputDescription>}
          {!fetchCustomerRes.isLoading && !doesCustomerExist && !fetchCustomerRes.isUninitialized && <InputDescription><IconX color="var(--color-se-red-1)" size={8} /> Customer does not exist</InputDescription>}
        </InputWrapper>

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} type="submit" disabled={!doesCustomerExist}>Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default EditCustomerParentModal;