import { Box, Button, Divider, Group, InputDescription, InputLabel, InputWrapper, Modal, NumberInput, Select, Skeleton, Stack, Text } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import { Map, Marker, Popup } from 'mapbox-gl';
import { mean, sum } from 'ramda';
import { FormEvent, MouseEvent, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFetchGenerationQuery } from 'amp/api/generators';
import { useCreateAssignmentMutation } from 'amp/api/programs';
import { getAsDOMElement } from 'amp/components/MapIcons/large';
import { useGenerator, useGeneratorsPage } from 'amp/store/generators/hooks';
import { getGeneratorsById } from 'amp/store/generators/selectors';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BasePaper from 'shared/components/Paper/basePaper';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { MAPBOXGL_ACCESS_TOKEN } from 'shared/constants/resources';
import { IUtilityGenerationData } from 'shared/types/assetEvents';
import { IGeneratorAssignment, displayValuesToRequestData, errorResponseToDisplayErrors } from 'shared/types/assignment';
import { IProgram } from 'shared/types/program';
import { UserRole } from 'shared/types/user';
import { getErrorMessagesFromApiError } from 'shared/utils/data';
import { getThisYearEnd, getThisYearStart, timestampToNumericDate } from 'shared/utils/dates';
import { numberToSiUnits } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import DeleteAssignmentModal from './deleteAssignmentModal';


const MapContext = createContext<null | Map>(null);

const CreateAssignmentModal = ({
  onClose,
  isOpen,
  programId,
}: { onClose: () => void, isOpen: boolean, programId: string }) => {
  const oci = useAppSelector(getViewingOpCoId);
  const [isLoading, setIsLoading] = useState(false);
  const [edit] = useCreateAssignmentMutation();

  // This covers the current amount of generators, but we will need to paginate here at some point I believe
  const generatorsRes = useGeneratorsPage({ page: 1, perPage: 500 });
  const generators = generatorsRes.data || [];

  const form = useForm({
    initialValues: {
      generatorId: null,
      start: null,
      end: null,
      percentGenerationDedicated: 100.0, // this is 100%
    }
  });

  const onSubmit = async (values: typeof form.values, e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);

    const body = displayValuesToRequestData({
      ...values,
      percentGenerationDedicatedTenThousandths: values.percentGenerationDedicated * 10_000,
      generatorId: values.generatorId || '',
    });
    try {
      await edit({ programId, body, customerId: oci }).unwrap();
      onCloseHandler();
      notifications.show({
        title: 'Success',
        message: 'Successfully created the assignment',
        color: "teal",
        icon: <IconCheck size={20} />,
      });
    } catch (err) {
      const errMsgs = getErrorMessagesFromApiError(err);
      if (errMsgs) {
        form.setErrors(errorResponseToDisplayErrors(errMsgs));
      }
      notifications.show({
        title: 'Error',
        message: 'There was an issue creating the assignment',
        color: 'red',
        icon: <IconX size={20} />,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const onCloseHandler = () => {
    form.reset();
    onClose();
  };


  const sortedGenerators = generators.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <>
      <Modal
        onClose={() => onCloseHandler()}
        opened={isOpen}
        title="Create a generator assignment for this program"
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          {/* TODO: use this component instead  https://react-select.com/async down the road */}
          <Select
            mt={24}
            label="Generator"
            description="The generator to assign to the program"
            withAsterisk
            searchable
            data={sortedGenerators.map(generator => ({ label: generator.name, value: generator.id }))}
            {...form.getInputProps('generatorId')}
          />
          <InputWrapper mt={24}>
            <InputLabel>Assignment start</InputLabel>
            <InputDescription>The date that the program should start receiving certificates from this generator</InputDescription>
            <DateInput {...form.getInputProps('start')} />
          </InputWrapper>
          <InputWrapper mt={24}>
            <InputLabel>Assignment end</InputLabel>
            <InputDescription>The date that the program should stop receiving certificates from this generator</InputDescription>
            <DateInput {...form.getInputProps('end')} />
          </InputWrapper>
          <NumberInput
            mt={24}
            withAsterisk
            label="Percent allocation"
            description="The percent of generators generation that should be given to this program"
            decimalScale={4}
            fixedDecimalScale
            max={100}
            min={0}
            {...form.getInputProps('percentGenerationDedicated')}
          />
          <Group justify="flex-end" mt="md">
            <Button loading={isLoading} type="submit">Submit</Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

const GeneratorAssignmentRow = ({ assignment }: { assignment: IGeneratorAssignment }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const oci = useAppSelector(getViewingOpCoId);
  const generatorRes = useGenerator(assignment.asset_id, assignment.customer_id);
  const generator = generatorRes.data;
  const map = useContext(MapContext);
  const nav = useNavigate();

  useEffect(() => {
    const latLng = generator?.location.lat_lng
    if (map && latLng) {
      const lng = typeof latLng[1] === 'number' ? latLng[1] : parseFloat(latLng[1]);
      const lat = typeof latLng[0] === 'number' ? latLng[0] : parseFloat(latLng[0]);
      const marker = new Marker({ element: getAsDOMElement(true) }).setLngLat([lng, lat]).addTo(map);
      marker.setPopup(new Popup().setHTML(`<div color="var(--color-blue-3)">${generator.name}</div>`))
    }
  }, [generator, map]);

  const start = assignment.data.configuration.assignment_start;
  const end = assignment.data.configuration.assignment_end;
  const generationRes = useFetchGenerationQuery({
    startDate: start || getThisYearStart().toISOString(),
    endDate: end || getThisYearEnd().toISOString(),
    resolution: '1d',
    generatorIds: [assignment.asset_id],
    customerIds: [generator?.customer_id || oci],
  }, { skip: !assignment.asset_id });
  const generation = generationRes.data?.data;
  // TODO: endpoint to fetch generator forecast between 2 dates
  // const forecastRes = useGeneratorForecast(assignment.asset_id, 2023);
  // const forecastData = forecastRes.data;
  const forecastedGenerationWh = sum((generation || []).map(gen => {
    const rawWh = (gen.data as IUtilityGenerationData).sum_generated_wh;
    const fuzzed = rawWh * 0.97;
    return fuzzed;
  }) || []);

  const totalGenWh = useMemo(() => {
    return sum(generation?.map(ae => (ae.data as IUtilityGenerationData).sum_generated_wh) || []);
  }, [generation]);

  const onClickDelete = async (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  const goToGenerator = (genId: string) => {
    if (genId) {
      nav(`/dashboard/inventory/sources/${genId}`);
    }
  }

  const formattedTotalGen = numberToSiUnits(totalGenWh);
  const formattedForecastGen = numberToSiUnits(forecastedGenerationWh);
  return (
    <>
      <Group pt="20px" pb="20px" gap="32px" w="100%" onClick={() => goToGenerator(generator?.id || '')} className="program-assignments--assignment-row">
        <Stack gap="8px" w="200px">
          <Skeleton visible={generatorRes.isLoading}>
            <div className="program-page--assignment-gen-name">{generator?.name || 'Unknown Name'}</div>
          </Skeleton>
          <div className="program-page--assignment-sub-text">
            {`${start ? timestampToNumericDate(start) : 'not specified'} - ${end ? timestampToNumericDate(end) : 'not specified'}`}
          </div>
        </Stack>

        <Group justify="space-between" align="center" w="calc(100% - 232px)">
          <Stack gap="4px" align="center">
            <div className="program-page--assignment-percent-stat">
              {(assignment.data.configuration.percent_generation_dedicated_ten_thousandths / 10_000).toFixed(1)} %
            </div>
            <div className="program-page--assignment-sub-text">Commitment</div>
          </Stack>
          <Stack gap="4px" align="center">
            <Skeleton visible={generationRes.isLoading}>
              <Group gap="4px" justify="center" align="center">
                {/* TODO: error state, we can prompt user to also kick off a forecast */}
                <div className="program-page--assignment-stat forecast">{
                  generation ? formattedForecastGen.slice(0, -1) : <em>Unknown</em>}
                </div>
                <div className="program-page--assignment-stat units">{formattedForecastGen.slice(-1)}Wh</div>
              </Group>
            </Skeleton>
            <div className="program-page--assignment-sub-text">Generation Forecast</div>
          </Stack>
          <Stack gap="4xp" align="center">
            <Skeleton visible={generationRes.isLoading}>
              <Group gap="xs" align="center" justify="center">
                <div className="program-page--assignment-stat">{formattedTotalGen.slice(0, -1)}</div>
                <div className="program-page--assignment-stat units">{formattedTotalGen.slice(-1)}Wh</div>
              </Group>
            </Skeleton>
            <div className="program-page--assignment-sub-text">Generation Actual</div>
          </Stack>
          <RoleRequired role={UserRole.ADMIN}>
            <Box p={8} onClick={onClickDelete} className="program-page--delete-assignment-container">
              <IconTrash
                cursor="pointer"
                size="20px"
              />
            </Box>
          </RoleRequired>
        </Group>
      </Group>
      <Divider w="100%" />
      <DeleteAssignmentModal
        onClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        assignmentId={assignment.id}
        programId={assignment.retail_program_id}
        customerId={assignment.customer_id}
      />
    </>
  );
}

export default function GeneratorAssignments({
  program,
  assignments,
}: { program: IProgram, assignments: IGeneratorAssignment[] }) {
  const [createModelOpen, setCreateModelOpen] = useState(false);
  const generators = useAppSelector(s => getGeneratorsById(s, assignments.map(a => a.asset_id)));
  const map = useRef<Map | null>(null);

  useEffect(() => {
    if (!map.current) {
      map.current = new Map({
        accessToken: MAPBOXGL_ACCESS_TOKEN,
        container: 'program-page--assigned-generator-map-container',
        attributionControl: false,
        interactive: false,
        center: [-83, 32], // [lng, lat] is expected
        zoom: 4.8,
        style: 'mapbox://styles/mapbox/light-v10',
        renderWorldCopies: false,
      });
    }
  });

  useEffect(() => {
    if (generators.length && map.current) {
      const latLngs = generators.map(g => g.location.lat_lng).filter(latLng => !!latLng) as Array<number[] | string[]>;
      const meanLng = mean(latLngs.map(latLng => typeof latLng[1] === 'number' ? latLng[1] : parseFloat(latLng[1])));
      const meanLat = mean(latLngs.map(latLng => typeof latLng[0] === 'number' ? latLng[0] : parseFloat(latLng[0])))
      if (latLngs.length) {
        map.current.setCenter([meanLng, meanLat]);
      }
    }
  }, [generators, map]);

  return (
    <>
      <BasePaper
        titleContent={<div>Program Assignments</div>}
        actions={
          <RoleRequired role={UserRole.ADMIN}>
            <Button
              className="programs-page--create-button"
              onClick={() => setCreateModelOpen(true)}
              leftSection={<IconPlus size={20} />}
            >
              New Assignment
            </Button>
          </RoleRequired>
        }
      >
        <MapContext.Provider value={map.current}>
          <div className="program-page--assignments-row">
            <Box w="30%" h="400px">
              <div id="program-page--assigned-generator-map-container" />
            </Box>
            <div className="program-page--assignments-list-container">
              {assignments.length === 0 && <Text>No existing assignments, assign a generator to begin</Text>}
              {assignments.map(assignment => <GeneratorAssignmentRow assignment={assignment} key={assignment.id} />)}
            </div>
          </div>
        </MapContext.Provider>
      </BasePaper>

      <CreateAssignmentModal
        isOpen={createModelOpen}
        onClose={() => {
          setCreateModelOpen(false);
        }}
        programId={program.id}
      />
    </>
  );
}