import { Fieldset, Input, SegmentedControl, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';

import { useUpsertMySettingMutation } from 'shared/api/users';
import { getMeasurementPreference } from 'shared/store/user/selectors';
import { MeasurementDisplayPreference, UserProperty } from 'shared/types/user';
import { useAppSelector } from 'store';
import './style.css';


const SettingsPreferencesView = () => {
  const metricPref = useAppSelector(getMeasurementPreference);
  const [upsert] = useUpsertMySettingMutation();

  const setMetricPref = (value: string) => {
    upsert({
      value,
      name: UserProperty.MEASUREMENT_PREF,
    }).unwrap()
      .then(() => {
        notifications.show({
          title: 'Success',
          message: 'Saved your settings preferences',
          color: "teal",
          icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
        });
      })
      .catch(() => {
        notifications.show({
          title: 'Error',
          message: 'Failed to save your settings preferences',
          icon: <IconX style={{ width: '20px', height: '20px' }} />,
          color: "red",
        });
      })
  };

  return (
    <div>
      <Title order={3} m={'lg'} className="singularity--auditLogs-title">
        Preferences
      </Title>
      <div className="audit-logs-dropdown--container">
        <Fieldset legend="Chart & metrics preferences">
          <Input.Wrapper label="Measurement display preference" description="Choose how you would like various metrics to appear in the application">
            <SegmentedControl data={Object.values(MeasurementDisplayPreference)} defaultValue={metricPref} onChange={setMetricPref} />
          </Input.Wrapper>
        </Fieldset>
      </div>
    </div>
  );

};

export default SettingsPreferencesView;