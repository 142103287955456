import { createSelector } from "reselect";
import { IAlgorithmV1Config, IAlgorithmV3Config } from "shared/types/allocation";
import { IDashboardPagination } from "shared/types/api";
import { nMinutesAgo } from "shared/utils/dates";
import { RootState } from "store";

const root = (state: RootState) => state.amp__allocation_runs;

const byId = createSelector(root, s => s.byId);

export const getAllocationRunById = createSelector(
  byId,
  (_: RootState, allocationRunId: string) => allocationRunId,
  (runsById, id) => runsById[id]
);


export const getAllocationRunsById = createSelector(
  byId,
  (_: RootState, allocationRunIds: string[]) => allocationRunIds,
  (runsById, ids) => ids.map(id => runsById[id]).filter(x => !!x),
);


const getAllocationRunConfig = createSelector(
  getAllocationRunById,
  (run) => ({
    config: run?.allocation_config.algorithm_config,
    version: run?.allocation_config.algorithm_config_version,
  })
);


export const getAllocationRunStartAndEnd = createSelector(
  getAllocationRunConfig,
  ({ config, version }) => {
    if (!config || !version) {
      return {
        start: null,
        end: null,
      }
    }
    if (version === 'v1' || version === 'v2') {
      const year = (config as IAlgorithmV1Config).year_for_run;
      const start = new Date();
      const end = new Date();
      start.setFullYear(year, 0, 1);
      start.setUTCHours(0, 0, 0, 0);
      end.setFullYear(year, 11, 31);
      end.setUTCHours(23, 59, 59, 999);

      return {
        start,
        end,
      }
    } else if (version === 'v3' || version === 'v4') {
      const startStr = (config as IAlgorithmV3Config).start;
      const endStr = (config as IAlgorithmV3Config).end;
      return {
        start: new Date(startStr),
        end: new Date(endStr),
      }
    }
  }
)

const summaryResultsById = createSelector(root, s => s.summaryResultsById);

export const getAllocationSummaryResultById = createSelector(
  summaryResultsById,
  (_: RootState, allocationRunId: string) => allocationRunId,
  (runsById, id) => runsById[id],
);

const inputsById = createSelector(root, s => s.inputsById);

export const getAllocationInputDataById = createSelector(
  inputsById,
  (_: RootState, allocationRunId: string) => allocationRunId,
  (inputsById, id) => inputsById[id],
);

const customerResultsById = createSelector(root, s => s.customerResultsById);

export const getAllocationCustomerResultByRunIdAndCustomerId = createSelector(
  customerResultsById,
  (_: RootState, ids: { runId: string, customerId: string }) => ids,
  (customerResultsById, ids) => customerResultsById[ids.runId]?.[ids.customerId],
);

const stdDeliveryResultsById = createSelector(root, s => s.stdDeliveryResultsById);

export const getStdDeliveryResultById = createSelector(
  stdDeliveryResultsById,
  (_: RootState, allocationRunId: string) => allocationRunId,
  (stdDeliveryResultsById, id) => stdDeliveryResultsById[id],
);

const programResultsById = createSelector(root, s => s.programResultsById);

export const getAllocationProgramResultByRunIdAndProgramId = createSelector(
  programResultsById,
  (_: RootState, ids: { runId: string, programId: string }) => ids,
  (programResultsById, ids) => programResultsById[ids.runId]?.[ids.programId],
);

const runPageResponseByOci = createSelector(
  root,
  (state) => state.runsPageResponseByOCI
);

const getRunPageResponseByOci = createSelector(
  runPageResponseByOci,
  (_: RootState, oci: string) => oci,
  (pageResponseByOci, oci) => {
    const ociRes = pageResponseByOci[oci];
    if (!ociRes) {
      return {
        data: null,
        fetchFailed: false,
        isFetching: false,
        lastReceived: null,
        pagination: null,
      }
    } else {
      return ociRes;
    }
  }
);

export const getUtilityRunsPageLoading = createSelector(
  getRunPageResponseByOci,
  ociRes => ociRes.isFetching,
);

export const getUtilityRuns = createSelector(
  byId,
  getRunPageResponseByOci,
  (runsById, ociRes) => ociRes.data?.map(rid => runsById[rid]) || []
);

export const getUtilityRunsPagination = createSelector(
  getRunPageResponseByOci,
  (ociRes) => ociRes.pagination as null | IDashboardPagination
)


export const getUtilityRunsPageError = createSelector(
  getRunPageResponseByOci,
  ociRes => ociRes.fetchFailed,
);

const getUtilityRunsPageArgs = createSelector(
  getRunPageResponseByOci,
  ociRes => ociRes.serializedArgs,
);


export const shouldFetchUtilityRunsPage = createSelector(
  getRunPageResponseByOci,
  (_: RootState, oci: string, serializedArgs: string) => serializedArgs,
  getUtilityRunsPageArgs,
  (ociRes, newArgs, previousArgs) => {
    if (!ociRes) {
      return true;
    }
    const isExpired = ociRes.lastReceived === null || ociRes.lastReceived < nMinutesAgo(5);
    if (isExpired && !ociRes.isFetching && !ociRes.fetchFailed) {
      return true
    }
    const didArgsChange = newArgs !== previousArgs;
    if (didArgsChange) {
      return true
    }
    return false;
  }
)

const getBulkFetchAllocationInputsResponse = createSelector(
  root,
  (rootState) => rootState.bulkFetchAllocationInputsResponse,
);

export const getBulkFetchAllocationInputsLoading = createSelector(
  getBulkFetchAllocationInputsResponse,
  (bulkInputsResp) => bulkInputsResp.isFetching,
);

export const getBulkFetchAllocationInputsArgs = createSelector(
  getBulkFetchAllocationInputsResponse,
  bulkInputsResp => bulkInputsResp.serializedArgs,
);

export const getBulkFetchInputsError = createSelector(
  getBulkFetchAllocationInputsResponse,
  (inputsResp) => inputsResp.fetchFailed,
);

export const getBulkFetchInputsData = createSelector(
  getBulkFetchAllocationInputsResponse,
  (inputsResp) => inputsResp.data || {},
);

export const shouldBulkFetchAllocationInputs = createSelector(
  getBulkFetchAllocationInputsResponse,
  (_: RootState, serializedArgs: string) => serializedArgs,
  getBulkFetchAllocationInputsArgs,
  (bulkFetchRes, newArgs, previousArgs) => {
    if (!bulkFetchRes) {
      return true;
    }
    const isExpired = bulkFetchRes.lastReceived === null || bulkFetchRes.lastReceived < nMinutesAgo(5);
    if (isExpired && !bulkFetchRes.isFetching && !bulkFetchRes.fetchFailed) {
      return true
    }
    const didArgsChange = newArgs !== previousArgs;
    if (didArgsChange) {
      return true
    }
    return false;
  }
);
