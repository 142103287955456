import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';

import { useDeleteAssignmentMutation } from 'amp/api/programs';

const DeleteAssignmentModal = ({
  onClose,
  isOpen,
  assignmentId,
  programId,
  customerId,
}: { onClose: () => void, isOpen: boolean, assignmentId: string, programId: string, customerId: string }) => {
  const [confirmText, setConfirmText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteAssignment] = useDeleteAssignmentMutation();

  const onDeleteClicked = async () => {
    setLoading(true);
    try {
      await deleteAssignment(
        { assignmentId, programId, customerId }
      ).unwrap();
      notifications.show({
        title: 'Success',
        message: 'Successfully deleted the assignment',
        color: "teal",
        icon: <IconCheck size={20} />,
      });
    } catch (e) {
      notifications.show({
        title: 'Error',
        message: 'There was an issue deleting the assignment',
        color: 'red',
        icon: <IconX size={20} />,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      onClose={onClose}
      opened={isOpen}
      title="Delete Assignment"
    >
      <Stack>
        <Text fz={16} c="var(--color-neutral-12)">
          Are you sure you want to delete this generator assignment? It will no longer appear in the list of assignments and will need to be re-created if needed.
        </Text>
        <TextInput label="Type DELETE below to confirm" onChange={e => setConfirmText(e.target.value)} />
        <Button onClick={onDeleteClicked} disabled={confirmText !== 'DELETE'} maw={200} loading={loading}>
          Delete
        </Button>
      </Stack>
    </Modal>
  );
}

export default DeleteAssignmentModal;