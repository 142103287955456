import { useEffect, useMemo } from 'react';

import { IListProgramsArgs, IListSubscriptionsArgs, useFetchProgramQuery, usePaginateProgramsQuery, usePaginateSubscriptionsQuery } from 'amp/api/programs';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { getOpcos } from 'shared/store/user/selectors';
import { IProgram } from 'shared/types/program';
import { useAppDispatch, useAppSelector } from 'store';
import { getAssignmentsById, getProgramById, getProgramsById, getSubscriptionsById, getUtilityProgramPageError, getUtilityProgramPageLoading, getUtilityPrograms, shouldFetchUtilityProgramPage } from './selectors';
import { fetchUtilityPrograms, receiveAssignments, receivePrograms, receiveSubscriptions } from './slice';

export const useProgram = (programId: string) => {
  const oci = useAppSelector(getViewingOpCoId);
  const program = useAppSelector(s => getProgramById(s, programId));
  const res = useFetchProgramQuery({id: programId, customerId: program?.customer_id || oci});
  const dispatch = useAppDispatch();
  const subIds = useMemo(() => res.data?.subscriptions.map(({ id }) => id) || [], [res.data]);
  const asgnIds = useMemo(() => res.data?.assignments.map(({ id }) => id) || [], [res.data]);
  const subscriptions = useAppSelector(s => getSubscriptionsById(s, subIds));
  const assignments = useAppSelector(s => getAssignmentsById(s, asgnIds));

  useEffect(() => {
    if (res.data) {
      const prog = {
        ...res.data.program,
        assignments: res.data.assignments,
        subscriptions: res.data.subscriptions,
      };

      dispatch(receivePrograms([prog]));
      dispatch(receiveSubscriptions(res.data.subscriptions));
      dispatch(receiveAssignments(res.data.assignments));
    }
  }, [res.data, dispatch, programId]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: {
      program: program as IProgram | undefined,
      assignments,
      subscriptions,
    }
  }
};

export const useProgramsPage = ({ page, perPage }: IListProgramsArgs) => {
  const oci = useAppSelector(getViewingOpCoId);
  const res = usePaginateProgramsQuery({ page, perPage, customerId: oci });
  const dispatch = useAppDispatch();
  const programIds = useMemo(() => res.data?.data.map(({ id }) => id) || [], [res.data]);
  const programs = useAppSelector(s => getProgramsById(s, programIds));

  useEffect(() => {
    if (res.data) {
      dispatch(receivePrograms(res.data.data));
    }
  }, [res.data, dispatch, page, perPage]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: programs,
    pagination: res.data?.meta.pagination,
  }
};

export const useSubscriptionsPage = ({ page, perPage, customerId, childCustomerId, programId }: IListSubscriptionsArgs) => {
  const oci = useAppSelector(getViewingOpCoId);
  const res = usePaginateSubscriptionsQuery({ page, perPage, childCustomerId: childCustomerId, programId, customerId: customerId || oci});
  const dispatch = useAppDispatch();
  const subscriptionIds = useMemo(() => res.data?.data.map(({ id }) => id) || [], [res.data]);
  const subscriptions = useAppSelector(s => getSubscriptionsById(s, subscriptionIds));

  useEffect(() => {
    if (res.data) {
      dispatch(receiveSubscriptions(res.data.data));
    }
  }, [res.data, dispatch]);

  return {
    ...res,
    isFirstTimeLoading: res.isLoading,
    isRefreshingData: res.isFetching,
    data: subscriptions,
    pagination: res.data?.meta.pagination,
  }
};

export const useUtilityPrograms = ({ page, perPage, statuses }: IListProgramsArgs) => {
  const dispatch = useAppDispatch();
  const opcoId = useAppSelector(getViewingOpCoId);
  const opcos = useAppSelector(getOpcos);

  const customerIds = opcoId ? [opcoId] : opcos.map(d => d.id);
  const oci = customerIds.sort().join(',');

  const shouldFetch = useAppSelector(s => shouldFetchUtilityProgramPage(s, oci))

  // This should cover the scope of the pilot
  if (shouldFetch) {
    dispatch(fetchUtilityPrograms({page, perPage, statuses}));
  }
  const isLoading = useAppSelector(s => getUtilityProgramPageLoading(s, oci));
  const isError = useAppSelector(s => getUtilityProgramPageError(s, oci));
  const data = useAppSelector(s => getUtilityPrograms(s, oci));

  return {
    isLoading,
    isError,
    data,
  }
}