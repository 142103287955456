import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';

import { usePatchCustomerReportMutation } from 'amp/api/reports';
import { CustomerReportStatus } from 'shared/types/report';

const ArchiveReportModal = ({
  onClose,
  isOpen,
  reportId,
}: { onClose: (didSucceed: boolean) => void, isOpen: boolean, reportId: string }) => {
  const [confirmText, setConfirmText] = useState<string>('');
  const [loading, setIsLoading] = useState<boolean>(false);
  const [patch] = usePatchCustomerReportMutation();

  const onCloseHandler = (didSucceed = false) => {
    onClose(didSucceed);
  };

  const onDeleteClicked = () => {
    setIsLoading(true);
    patch({ reportId, body: { status: CustomerReportStatus.ARCHIVED } })
      .then(() => notifications.show({
        title: 'Success',
        message: 'Successfully deleted the report',
        color: "teal",
        icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
      }))
      .catch(() => notifications.show({
        title: 'Error',
        message: 'Failed to delete the report',
        icon: <IconX style={{ width: '20px', height: '20px' }} />,
        color: "red",
      }))
      .finally(() => {
        setIsLoading(false);
      })
  }

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Delete Report"
    >
      <Stack>
        <Text fz={16} c="var(--color-neutral-12)">
          Are you sure you want to delete this report? It will no longer appear in the list of reports and the results will no longer be accessible.
        </Text>
        <TextInput label="Type DELETE below to confirm" onChange={e => setConfirmText(e.target.value)} />
        <Button onClick={onDeleteClicked} disabled={confirmText !== 'DELETE'} maw={200} loading={loading}>
          Delete
        </Button>
      </Stack>
    </Modal>
  );
}

export default ArchiveReportModal;