import { Accordion, Box, Button, Divider, Group, LoadingOverlay, Pill, Select, Skeleton, Stack, Text } from '@mantine/core';
import { IconArrowDown, IconArrowUp, IconBolt, IconChevronDown, IconDownload, IconKeyframeAlignHorizontal } from '@tabler/icons-react';
import { indexBy, prop, sum } from 'ramda';
import { useMemo, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { useFetchReportCustomerResultsQuery, useFetchReportInputDataQuery, useFetchReportStdDeliveryResultsQuery, useFetchReportSummaryResultsQuery } from 'amp/api/allocationRuns';
import { useFetchCustomerReportQuery } from 'amp/api/reports';
import { useAllocationRunCustomerOutputData, useAllocationRunInputData, useAllocationRunStdDeliveryOutputData, useAllocationRunSummaryOutputData } from 'amp/store/allocationRuns/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { usePaginateCustomersQuery } from 'shared/api/customers';
import BaseChart from 'shared/components/Chart/baseChart';
import BasePaper from 'shared/components/Paper/basePaper';
import BaseTable, { IBaseTableColumn } from 'shared/components/Table/baseTable';
import { useCustomersPage, useUtilityCustomers } from 'shared/store/customers/hooks';
import { getCurrentUser, getMeasurementPreference } from 'shared/store/user/selectors';
import { CustomerType, getCustomerLogoPath, isUtilityParentCustomer } from 'shared/types/customer';
import { GeneratorFuelCategories, fuelDisplayName, isCfeFuel } from 'shared/types/generator';
import { CustomerReport } from 'shared/types/report';
import { MeasurementDisplayPreference } from 'shared/types/user';
import { groupData } from 'shared/utils/chart';
import { getColorForFuel } from 'shared/utils/color';
import { timestampToNumericDate } from 'shared/utils/dates';
import { numberToSiFormat } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import CleanEnergyCertsAssigned from './certificatesAssigned';
import CfeComparisonBarChart from './cfeComparisonBars';
import HourlyCfeScoreChart from './cfeHourlyScoreChart';
import AllocationResultsCfePieChart from './cfePieChart';
import CustomerAllocationLegalDisclaimer from './legalDisclaimer';
import ConsumptionMatchingTimeSeries from './matchingTimeSeries';
import './style.css';
import SubAccountResultsTable from './subAccountResultsTable';


const whatIfTableColumns: IBaseTableColumn[] = [
  { displayValue: '', key: 'rowName', color: '#4d7396' },
  { displayValue: 'With Program Subscription', key: 'withSub', color: '#4d7396' },
  { displayValue: 'As Standard Customer', key: 'asSrp', color: '#4d7396' },
  { displayValue: 'Total Impact', key: 'total', color: '#4d7396' },
]

const AllocationResultsCustomer = ({ isReport = false }: { isReport?: boolean }) => {
  const { runId = '' } = useParams<{ runId: string }>();
  const [params, setParams] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);
  const customerId = params.get('c');
  const refToPrint = useRef<HTMLDivElement>(null);

  const currentCustomerId = useAppSelector(getCurrentUser)?.customer_id;
  const allocationRunInputs = useAllocationRunInputData(runId, oci || currentCustomerId, isReport);
  const customersRes = useUtilityCustomers();
  const childCustomersRes = usePaginateCustomersQuery({ page: 1, perPage: 20, statuses: ['active'], parentCustomerId: customerId, customerType: CustomerType.UTILITY_CUSTOMER_ACCOUNT }, { skip: !customerId });

  const res = useAllocationRunCustomerOutputData(runId, oci || currentCustomerId, customerId);
  const result = res.data;

  const summaryRes = useAllocationRunSummaryOutputData(runId, oci || currentCustomerId);
  const stdDeliveryRes = useAllocationRunStdDeliveryOutputData(runId, oci || currentCustomerId);
  const stdDeliveryResult = stdDeliveryRes.data;
  const summaryResult = summaryRes.data;
  const customers = customersRes?.data || [];
  const sortedCustomers = customers.sort((a, b) => a.name.localeCompare(b.name));
  const childCustomers = childCustomersRes.data?.data || [];

  const accountsRes = useCustomersPage({ page: 1, perPage: 1, parentCustomerId: customerId, customerType: CustomerType.UTILITY_CUSTOMER_ACCOUNT });
  const numAccounts = accountsRes.pagination?.total_items;
  const measurement = useAppSelector(getMeasurementPreference);
  const isMetric = measurement === MeasurementDisplayPreference.METRIC;
  const lbsConversion = isMetric ? 2.20462 : 1;

  const onPrintToPdf = useReactToPrint({ content: () => refToPrint.current });

  const onSelectCustomer = (custId: string | null) => {
    if (custId) {
      setParams(newParams => {
        newParams.set('c', custId);
        return newParams;
      });
    }
  }

  // if this page is viewing a report, fetch the data as a report not an allocation
  // TODO: this conditional logic is hard to follow, clean it up
  const reportRes = useFetchCustomerReportQuery({ id: runId, customerId: ''}, {skip: !isReport});
  const report = reportRes.data?.customer_report;
  const locations = report ? new CustomerReport(report).getReportOutputsLocations({ programId: '', customerId: customerId || undefined }) : null;
  const repCustomerResultsResp = useFetchReportCustomerResultsQuery(
    { customerResultsLocation: locations?.customer || '' },
    { skip: !isReport || !locations }
  );
  const repCustomerResults = repCustomerResultsResp.data;
  const repStdDeliveryResultsResp = useFetchReportStdDeliveryResultsQuery(
    { stdDeliveryResultsLocation: locations?.standardDelivery || '' },
    { skip: !isReport || !locations}
  );
  const repStdDeliveryResults = repStdDeliveryResultsResp.data;
  const repInputsDataResp = useFetchReportInputDataQuery(
    { inputLocation: locations?.input || '' },
    { skip: !isReport || !locations}
  );
  const repInputData = repInputsDataResp.data;
  const programs = repInputData?.programs || allocationRunInputs.data?.programs || [];
  const repSummaryResultsResp = useFetchReportSummaryResultsQuery(
    { summaryLocation: locations?.summary || '' },
    { skip: !isReport || !locations}
  );
  const repSummaryData = repSummaryResultsResp.data;

  const stdDelivery = isReport ? repStdDeliveryResults?.hourly_results : stdDeliveryResult?.hourly_results;
  const summary = isReport ? repSummaryData?.summary_results : summaryResult?.summary_results;
  const hourlyResults = useMemo(() => isReport ? repCustomerResults?.hourly_results || [] : result?.hourly_results || [], [result, repCustomerResults, isReport]);
  const hourlyResultsByHour = indexBy(prop('datetime'), hourlyResults);
  const totalLoadWh = Object.values(hourlyResults || {}).reduce((soFar, nextUp) => soFar + nextUp.consumption_wh, 0);

  // calculations for the CFE pie chart
  const [totalProgramEnergyWh, totalGridCfeWh, totalGridNonCfeWh] = useMemo(() => {
    let programsTotal = 0;
    let gridCfeTotal = 0;
    let gridNonCfeTotal = 0;
    if (!stdDelivery || !hourlyResults.length) {
      return [null, null, null];
    }

    stdDelivery?.forEach(stdHour => {
      // get the total program impact from that hour:
      const subscriptionResults = hourlyResultsByHour[stdHour.hour]?.subscription_program_generation || [];
      const allocatedGenBySubscription = subscriptionResults.map(subscriptionResult => subscriptionResult.allocated_wh);
      const totalFromAllSubs = sum(allocatedGenBySubscription);

      const consumptionWh = hourlyResultsByHour[stdHour.hour]?.consumption_wh || 0
      const actualAllocated = Math.min(totalFromAllSubs, consumptionWh);
      programsTotal += actualAllocated
      const unmatchedLoadWh = Math.max(consumptionWh - actualAllocated, 0);
      const totalStdMWh = sum(Object.values(stdHour.mix).map(d => d.mwh));

      if (!totalStdMWh) {
        // prevent divide by 0
        console.warn('found hour with 0 standard delivery in customer allocation results')
        return;
      }

      Object.entries(stdHour.mix).forEach(([fuelString, { mwh }]) => {
        const fuelPercentByHour = mwh / totalStdMWh;
        if (isCfeFuel(fuelString)) {
          gridCfeTotal += fuelPercentByHour * unmatchedLoadWh;
        } else {
          gridNonCfeTotal += fuelPercentByHour * unmatchedLoadWh;
        }
      });
    });

    return [programsTotal, gridCfeTotal, gridNonCfeTotal];
  }, [stdDelivery, hourlyResultsByHour, hourlyResults]);
  const totalMWh = sum([totalProgramEnergyWh || 0, totalGridCfeWh || 0, totalGridNonCfeWh || 0]) / 1_000_000;

  // calculations for emissions time series charts
  const [ciSeriesLbsPerMWh, totalEmissionsSeriesLbs] = useMemo(() => {
    const totalEmissionsAccLbsCo2: number[][] = [];
    const carbonIntensityTimeSeries: number[][] = [];
    if (!stdDelivery || !hourlyResults.length) {
      return [null, null];
    }

    let lbsCo2EmittedSoFar = 0;
    stdDelivery?.forEach(stdHour => {
      const xValue = new Date(stdHour.hour).valueOf();
      const hourlyConsumptionWh = hourlyResultsByHour[stdHour.hour]?.consumption_wh || 0;

      const subscriptionResults = hourlyResultsByHour[stdHour.hour]?.subscription_program_generation || [];
      const totalProgramsWh = sum(subscriptionResults.map(s => s.allocated_wh));

      if (totalProgramsWh < hourlyConsumptionWh) {
        const percentMissing = (hourlyConsumptionWh - totalProgramsWh) / hourlyConsumptionWh;
        const totalStdMWh = sum(Object.values(stdHour.mix).map(d => d.mwh));
        if (!totalStdMWh) { // prevent divide by 0
          return;
        }

        let totalGridCiLbsPerMWh = 0;
        Object.entries(stdHour.mix).forEach(([fuel, { mwh, sum_co2e_lbs }]) => {
          const percentOfMix = mwh / totalStdMWh;
          const fuelCiForHour = sum_co2e_lbs / (mwh || 1); // if mwh is 0 so is sum_co2e_lbs
          totalGridCiLbsPerMWh += fuelCiForHour * percentOfMix;
        });

        const emissionsLbs = totalGridCiLbsPerMWh * percentMissing * hourlyConsumptionWh / 1_000_000;
        lbsCo2EmittedSoFar += emissionsLbs;
        const programCi = (emissionsLbs / (hourlyConsumptionWh / 1_000_000)) / lbsConversion;
        carbonIntensityTimeSeries.push([xValue, programCi]);

        // only push results from the first hour of the day. accumulated data so this is better than grouping
        new Date(stdHour.hour).getUTCHours() === 0 && totalEmissionsAccLbsCo2.push([xValue, lbsCo2EmittedSoFar / lbsConversion]);
      } else {
        carbonIntensityTimeSeries.push([xValue, 0]);
        // only push results from the first hour of the day. accumulated data so this is better than grouping
        new Date(stdHour.hour).getUTCHours() === 0 && totalEmissionsAccLbsCo2.push([xValue, lbsCo2EmittedSoFar / lbsConversion]);
      }
    });

    const dailyCarbonIntensity = groupData(carbonIntensityTimeSeries.map(d => ({ epoch: d[0], value: d[1] })), 'day', 'mean');
    return [dailyCarbonIntensity, totalEmissionsAccLbsCo2];
  }, [stdDelivery, hourlyResults, hourlyResultsByHour, lbsConversion]);

  // need to fetch all generators in programs for this allocation - need fuel type
  // also calculate totals from program generators to display
  const hourlyGenerationMatchedByGeneratorIdWh: Record<string, Record<string, number>> = {};
  hourlyResults.forEach(hourlyResult => {
    hourlyResult.subscription_program_generation.forEach(sub => {
      Object.entries(sub.allocated_mwh_by_generator_id).forEach(([gid, mwh]) => {
        if (!hourlyGenerationMatchedByGeneratorIdWh[gid]) {
          hourlyGenerationMatchedByGeneratorIdWh[gid] = {[hourlyResult.datetime]: 0};
        }
        // if "hourlyResult.datetime" is not in the map, add it
        if (!hourlyGenerationMatchedByGeneratorIdWh[gid][hourlyResult.datetime]) {
          hourlyGenerationMatchedByGeneratorIdWh[gid][hourlyResult.datetime] = mwh * 1_000_000;
        } else {
          hourlyGenerationMatchedByGeneratorIdWh[gid][hourlyResult.datetime] += mwh * 1_000_000;
        }
      });
    });
  });
  const reportGenerators = allocationRunInputs.data?.generators || repInputData?.generators || [];
  const programGenerators = reportGenerators.filter(({id}) => Object.keys(hourlyGenerationMatchedByGeneratorIdWh).includes(id));

  // calculations for the fuel-type pie chart
  const generationByFuelMwh: { grid: Record<string, number>, program: Record<string, number> } | null = useMemo(() => {
    const gridGenByFuelMWh: Record<string, number> = {};
    const programGenByFuelMWh: Record<string, number> = {};

    if (!hourlyResults.length || !stdDelivery || !programGenerators) {
      return null;
    }

    stdDelivery?.forEach(stdHour => {
      const hourlyConsumptionWh = hourlyResultsByHour[stdHour.hour]?.consumption_wh || 0;
      if (hourlyConsumptionWh) { // nothing assigned when there's no consumption
        const subscriptionResults = hourlyResultsByHour[stdHour.hour]?.subscription_program_generation || [];
        const totalFromAllSubs = sum(subscriptionResults.map(s => s.allocated_wh));

        if (totalFromAllSubs >= hourlyConsumptionWh) {
          // if there's more generation assigned than load, assign proportional gen
          const percentMatched = hourlyConsumptionWh / totalFromAllSubs;
          // TODO: come back to this, allocated_mwh_by_generator_id is not always accurate
          subscriptionResults.forEach(subscriptionResult => {
            Object.entries(subscriptionResult.allocated_mwh_by_generator_id).forEach(([genId, genMWh]) => {
              const generator = programGenerators.find(g => g.id === genId);
              const fuelType = generator?.meta.fuel_category || 'Unknown';
              if (!programGenByFuelMWh[fuelType]) {
                programGenByFuelMWh[fuelType] = 0;
              }
              programGenByFuelMWh[fuelType] += (genMWh * percentMatched);
            });
          });
        } else {
          // assign all the subscription generation, since load > totalProgramGen
          // TODO: come back to this, allocated_mwh_by_generator_id is not always accurate
          subscriptionResults.forEach(subscriptionResult => {
            Object.entries(subscriptionResult.allocated_mwh_by_generator_id).forEach(([genId, genMWh]) => {
              const generator = programGenerators.find(g => g.id === genId);
              const fuelType = generator?.meta.fuel_category || 'Unknown';
              if (!programGenByFuelMWh[fuelType]) {
                programGenByFuelMWh[fuelType] = 0;
              }
              programGenByFuelMWh[fuelType] += genMWh;
            });
          });

          // assign from the standard mix for the remainder of load
          const totalStdMWh = sum(Object.values(stdHour.mix).map(d => d.mwh));
          if (!totalStdMWh) { // prevent divide by 0
            return;
          }

          const unmatchedLoadPercent = (hourlyConsumptionWh - totalFromAllSubs) / hourlyConsumptionWh;
          Object.entries(stdHour.mix).forEach(([fuel, { mwh }]) => {
            if (!gridGenByFuelMWh[fuel]) {
              gridGenByFuelMWh[fuel] = 0;
            }
            const percentOfStdMix = mwh / totalStdMWh;
            gridGenByFuelMWh[fuel] += unmatchedLoadPercent * percentOfStdMix * hourlyConsumptionWh / 1_000_000;
          });
        }
      }
    });

    return { grid: gridGenByFuelMWh, program: programGenByFuelMWh };
  }, [hourlyResults, hourlyResultsByHour, stdDelivery, programGenerators]);

  // const calculations for the what-if table (as SRP)
  const [totalEmissionsAsSrpLbs, percentCfeAsSrp] = useMemo(() => {
    if (!hourlyResults.length || !stdDelivery) {
      return [null, null];
    }

    let totalGridCfe = 0;
    let totalGridNonCfe = 0;
    let totalCarbonEmissionsLbs = 0;
    stdDelivery.forEach(stdHour => {
      const totalStdMWh = sum(Object.values(stdHour.mix).map(d => d.mwh));
      if (!totalStdMWh) { // prevent divide by 0
        return;
      }
      let totalGridCiForHour = 0;
      Object.entries(stdHour.mix).forEach(([fuel, { mwh, sum_co2e_lbs }]) => {
        const percentOfMix = mwh / totalStdMWh;
        const fuelCiForHour = sum_co2e_lbs / (mwh || 1); // if mwh is 0 so is sum_co2e_lbs
        totalGridCiForHour += percentOfMix * fuelCiForHour;
        if (isCfeFuel(fuel)) {
          totalGridCfe += (hourlyResultsByHour[stdHour.hour]?.consumption_wh || 0) * percentOfMix;
        } else {
          totalGridNonCfe += (hourlyResultsByHour[stdHour.hour]?.consumption_wh || 0) * percentOfMix;
        }
      });
      totalCarbonEmissionsLbs += totalGridCiForHour * (hourlyResultsByHour[stdHour.hour]?.consumption_wh || 0) / 1_000_000;
    });
    const percentCfeSrp = (totalGridCfe / ((totalGridCfe + totalGridNonCfe) || 1));
    return [totalCarbonEmissionsLbs / lbsConversion, percentCfeSrp];
  }, [hourlyResults, stdDelivery, hourlyResultsByHour, lbsConversion]);

  const fuelTypePieChartOptions: Highcharts.Options = {
    chart: {
      type: 'pie',
      height: 250,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 0,
      headerFormat: '<span style="color:{point.color}">\u25CF</span> {point.key}: ',
      pointFormat: '<b>{point.valStr} MWh</b><span> ({point.pct}%)</span>',
    },
    series: [{
      type: 'pie',
      name: 'Generation',
      showInLegend: true,
      innerSize: 70,
      data: [
        ...Object.entries(generationByFuelMwh?.program || {}).map(([fuel, genMWh]) => {
          return {
            name: `Program ${fuelDisplayName(fuel)}`,
            color: getColorForFuel(GeneratorFuelCategories[fuel as keyof typeof GeneratorFuelCategories]),
            y: genMWh,
            valStr: Math.round(genMWh).toLocaleString(),
            pct: Math.min(100, Math.round((genMWh / totalMWh) * 100)),
          }
        }),
        ...Object.entries(generationByFuelMwh?.grid || {}).map(([fuel, genMWh]) => {
          return {
            name: `Grid ${fuelDisplayName(fuel)}`,
            color: getColorForFuel(GeneratorFuelCategories[fuel as keyof typeof GeneratorFuelCategories]),
            y: genMWh,
            valStr: Math.round(genMWh).toLocaleString(),
            pct: Math.min(100, Math.round((genMWh / totalMWh) * 100)),
          }
        }),
      ],
    }],
  }

  const ciEmissionsChartOptions: Highcharts.Options = {
    chart: {
      height: '80px',
    },
    tooltip: {
      valueSuffix: ` ${isMetric ? 'kg' : 'lbs'} CO2e / MWh`,
      valueDecimals: 1,
      outside: true,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    series: [{
      type: 'line',
      name: 'Carbon Intensity',
      color: 'var(--color-blue-0)',
      data: ciSeriesLbsPerMWh || [],
    }],
  };

  const totalEmissionsChartOptions: Highcharts.Options = {
    chart: {
      height: '80px',
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(180, 180, 180, 0.5)'], // start
            [1, 'rgba(180, 180, 180, 0)'] // end
          ]
        },
      },
    },
    tooltip: {
      valueSuffix: ` ${isMetric ? 'kg' : 'lbs'} CO2e`,
      valueDecimals: 1,
      outside: true,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    series: [{
      type: 'area',
      name: 'Total Emissions',
      color: '#4E566D',
      data: totalEmissionsSeriesLbs || [],
    }],
  };

  const subscriptionsForCustomer = Object.entries(summary?.by_subscription_id || {}).filter(([, results]) => results.subscribed_customer_id === customerId);

  const summaryOrCustomersLoading = !summaryResult || summaryRes.isLoading || customersRes.isLoading;
  const reportSummaryIsLoading = !summary || repSummaryResultsResp.isLoading || repSummaryResultsResp.isFetching || reportRes.isLoading || reportRes.isFetching;
  const isLoadingSummary = isReport ? reportSummaryIsLoading : summaryOrCustomersLoading;
  if (isLoadingSummary) {
    return <div className="allocation-results-customer--scroll-container">
      <Box pos="relative" h="100%" w="100%">
        <LoadingOverlay visible={true} />
      </Box>
    </div>
  }

  const currentCustomer = sortedCustomers.find(c => c.id === customerId);
  const currentCustomerIsParent = isUtilityParentCustomer(currentCustomer);
  const hourlyOrStdDeliveryLoading = res.isLoading || stdDeliveryRes.isLoading || !stdDelivery || !result?.hourly_results;
  const reportHourlyOrStdDeliveryLoading = repCustomerResultsResp.isLoading || repCustomerResultsResp.isFetching || repStdDeliveryResultsResp.isLoading || repStdDeliveryResultsResp.isFetching;
  const isLoadingMainData = isReport ? reportHourlyOrStdDeliveryLoading : hourlyOrStdDeliveryLoading;
  const formattedTotalLoad = numberToSiFormat(totalLoadWh);
  const formattedGenerationMatched = numberToSiFormat(totalProgramEnergyWh || 0);
  const formattedGridCfeMatched = numberToSiFormat(totalGridCfeWh || 0);
  const totalEmissionsLbs = totalEmissionsSeriesLbs?.length ? totalEmissionsSeriesLbs[totalEmissionsSeriesLbs.length - 1][1] : 0;
  const averageCiLbsPerMWh = isNaN(totalEmissionsLbs) || isNaN(totalLoadWh) ? 0 : totalEmissionsLbs / (totalLoadWh / 1_000_000);
  const formattedTotalEmissions = numberToSiFormat(totalEmissionsLbs);
  const formattedSrpTotalEmissions = numberToSiFormat(totalEmissionsAsSrpLbs || 0);
  const formattedTotalEmissionsDifference = numberToSiFormat(Math.abs(totalEmissionsLbs - (totalEmissionsAsSrpLbs || 0)));
  const percentDifferenceCfe = (((((totalGridCfeWh || 0) + (totalProgramEnergyWh || 0)) / (totalLoadWh || 1)) - ((totalGridCfeWh || 0) / (totalLoadWh || 1))) * 100);
  const averageCiAsSrpLbsPerMwh = (totalEmissionsAsSrpLbs || 0) / ((totalLoadWh / 1_000_000) || 1);
  const currentCustomerHasSubscription = subscriptionsForCustomer.length > 0;
  const whatIfTableRows = [
    {
      id: 'cfeMatch',
      rowName: 'CFE Matched',
      withSub: `${((((totalGridCfeWh || 0) + (totalProgramEnergyWh || 0)) / (totalLoadWh || 1)) * 100).toFixed(1)} %`,
      asSrp: `${((percentCfeAsSrp || 0) * 100).toFixed(1)} %`,
      total: <Group justify="flex-start">
        {percentDifferenceCfe > 0 ? <IconArrowUp size={15} color="var(--color-blue-0)" /> : <IconArrowDown size={15} color="var(--color-blue-0)" />}
        <Text fw={700} fz={10}>
          {percentDifferenceCfe.toFixed((1))}%
        </Text>
      </Group>
    },
    {
      id: 'totalEmissions',
      rowName: 'Total Emissions',
      withSub: <>{formattedTotalEmissions.value}{formattedTotalEmissions.unitPrefix} {isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e</>,
      asSrp: <>{formattedSrpTotalEmissions.value}{formattedSrpTotalEmissions.unitPrefix} {isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e</>,
      total: <Group justify="flex-start">
        {(totalEmissionsLbs - (totalEmissionsAsSrpLbs || 0)) > 0 ? <IconArrowUp size={15} color="var(--color-blue-0)" /> : <IconArrowDown size={15} color="var(--color-blue-0)" />}
        <Text fw={700} fz={10}>
          {formattedTotalEmissionsDifference.value}{formattedTotalEmissionsDifference.unitPrefix} {isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e
        </Text>
      </Group>
    },
    {
      id: 'carbonIntensity',
      rowName: 'Carbon Intensity',
      withSub: <>{averageCiLbsPerMWh.toFixed(1)} {isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e/MWh</>,
      asSrp: <>{(averageCiAsSrpLbsPerMwh || 0).toFixed(1)} {isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e/MWh</>,
      total: <Group justify="flex-start">
        {(averageCiLbsPerMWh - (averageCiAsSrpLbsPerMwh || 0)) > 0 ? <IconArrowUp size={15} color="var(--color-blue-0)" /> : <IconArrowDown size={15} color="var(--color-blue-0)" />}
        <Text fw={700} fz={10}>
          {Math.abs(averageCiLbsPerMWh - (averageCiAsSrpLbsPerMwh || 0)).toFixed(1)} {isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e/MWh
        </Text>
      </Group>
    },
  ];

  return <div>
    <div className="allocation-results-customer--header-controls-container">
      {!isReport && <Select
        value={customerId}
        data={sortedCustomers.map(c => ({ value: c.id, label: c.name }))}
        onChange={onSelectCustomer}
        rightSection={<IconChevronDown size={20} />}
        miw="200px"
        placeholder="Select a customer"
        className="customer-view-consumption-resolution--select"
      />}
      <Group gap="12px">
        <Button className="allocation-results-customer--button" rightSection={<IconDownload width={16} height={16} />} onClick={onPrintToPdf}>
          Download PDF
        </Button>
      </Group>
    </div>

    <div className={`allocation-results-customer--scroll-container ${isReport ? 'report' : ''}`}>
      {customerId && currentCustomer && <Stack gap={8} p="0px 60px">
        <img className="allocation-results--logo" src={getCustomerLogoPath(currentCustomer)} width={150} alt={`${currentCustomer.name} logo`} />
        <Skeleton maw="200px" visible={accountsRes.isLoading || accountsRes.isFetching}>
          <Text fz="12" c="var(--color-blue-3)">
            {numAccounts?.toLocaleString() || 0} sub-accounts total
          </Text>
        </Skeleton>
      </Stack>}

      {currentCustomerIsParent && <BasePaper className="allocation-results-customer--no-load-container">
        Please select a child customer to begin
        <Select
          // value={customerId}
          data={childCustomers.map(c => ({ value: c.id, label: c.name }))}
          onChange={onSelectCustomer}
          rightSection={<IconChevronDown size={20} />}
          miw="200px"
          placeholder="Select a customer"
          className="customer-view-consumption-resolution--select"
        />
      </BasePaper>}
      {isLoadingMainData && customerId && <Box pos="absolute" w="100%" h="80%">
        <LoadingOverlay bg="rgba(0, 0, 0, 0)" visible={true} />
      </Box>}
      {!isLoadingMainData && customerId && !currentCustomerIsParent && totalLoadWh === 0 &&
        <BasePaper className="allocation-results-customer--no-load-container">
          The selected customer had no load during the allocation period when this allocation was created.
        </BasePaper>
      }
      {!isLoadingMainData && customerId && totalLoadWh > 0 &&
        <Stack gap={16} p="0px 60px" ref={refToPrint}>
          <div className="allocation-results-customer--section-title">
            {currentCustomerHasSubscription ? 'Program Overview' : 'Overview'}
          </div>
          <Group gap={16} mb={44} align="flex-start">
            <AllocationResultsCfePieChart
              programCfe={totalProgramEnergyWh}
              gridCfe={totalGridCfeWh}
              gridNonCfe={totalGridNonCfeWh}
              customerHasSubscription={currentCustomerHasSubscription}
            />
            <Stack gap={16} className="allocation-results-customer--topline-stats-container">
              <BasePaper titleContent="Total Energy">
                <Group justify={currentCustomerHasSubscription ? 'space-between' : 'space-evenly'} pr={32}>
                  <Stack gap={4}>
                    <Group align="baseline" gap={4}>
                      <Text fz={32} fw={700}>
                        {formattedTotalLoad.value}
                      </Text>
                      <Text fz={20} c="var(--color-grey-4)">
                        {`${formattedTotalLoad.unitPrefix}Wh`}
                      </Text>
                    </Group>
                    <Text fz={12} c="var(--color-grey-4)">Total energy consumption</Text>
                  </Stack>
                  <Divider orientation="vertical" />
                  {currentCustomerHasSubscription && <>
                    <Stack gap={4}>
                      <Group align="baseline" gap={4}>
                        <Text fz={32} fw={700} c="var(--color-green-2)">
                          {formattedGenerationMatched.value}
                        </Text>
                        <Text fz={20} c="var(--color-grey-4)">
                          {`${formattedGenerationMatched.unitPrefix}Wh`}
                        </Text>
                      </Group>
                      <Text fz={12} c="var(--color-grey-4)">Program generation matched</Text>
                    </Stack>
                    <Divider orientation="vertical" />
                  </>}
                  <Stack gap={4}>
                    <Group align="baseline" gap={4}>
                      <Text fz={32} fw={700} c="var(--color-green-2)">
                        {formattedGridCfeMatched.value}
                      </Text>
                      <Text fz={20} c="var(--color-grey-4)">
                        {`${formattedGridCfeMatched.unitPrefix}Wh`}
                      </Text>
                    </Group>
                    <Text fz={12} c="var(--color-grey-4)">Grid CFE matched</Text>
                  </Stack>
                </Group>
              </BasePaper>
              <BasePaper titleContent="Emissions">
                <Group gap={40}>
                  <Stack w="calc(50% - 44px)" gap={4}>
                    <Group align="baseline" gap={4}>
                      <Text fz={32} fw={700} c="var(--color-blue-2)">
                        {formattedTotalEmissions.value} {formattedTotalEmissions.unitPrefix}
                      </Text>
                      <Text fz={20} c="var(--color-grey-4)">
                        {isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e
                      </Text>
                    </Group>
                    <Text fz={12} c="var(--color-grey-4)">Total emissions</Text>
                    <BaseChart overrideOptions={totalEmissionsChartOptions} />
                  </Stack>
                  <Divider orientation="vertical" />
                  <Stack w="calc(50% - 44px)" gap={4}>
                    <Group align="baseline" gap={4}>
                      <Text fz={32} fw={700} c="var(--color-blue-2)">
                        {isNaN(averageCiLbsPerMWh) ? '0.0' : averageCiLbsPerMWh.toFixed(1)}
                      </Text>
                      <Text fz={20} c="var(--color-grey-4)">
                        {isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e/MWh
                      </Text>
                    </Group>
                    <Text fz={12} c="var(--color-grey-4)">Carbon intensity</Text>
                    <BaseChart overrideOptions={ciEmissionsChartOptions} />
                  </Stack>
                </Group>
              </BasePaper>
            </Stack>
          </Group>

          <div className="print-page-break" />
          <div className="allocation-results-customer--section-title">
            {currentCustomerHasSubscription ? 'Program Details' : 'Details'}
          </div>
          <ConsumptionMatchingTimeSeries isReport={isReport} />
          <Group gap={16} align="flex-start">
            <BasePaper titleContent="Generation Types" className="allocation-results-customer--fuel-pie-container">
              <BaseChart overrideOptions={fuelTypePieChartOptions} />
            </BasePaper>

            <CleanEnergyCertsAssigned
              currentCustomerHasSubscription={currentCustomerHasSubscription}
              totalProgramEnergyWh={totalProgramEnergyWh}
              totalGridCfeWh={totalGridCfeWh}
              generationByFuelMwh={generationByFuelMwh}
              hourlyGenerationMatchedByGeneratorIdWh={hourlyGenerationMatchedByGeneratorIdWh}
              generators={repInputData?.generators || allocationRunInputs.data?.generators}
            />
          </Group>

          <div className="print-page-break" />
          <HourlyCfeScoreChart isReport={isReport} />

          {currentCustomerHasSubscription && <>
            <div className="print-page-break" />
            <SubAccountResultsTable isReport={isReport} />

            <div className="print-page-break" />
            <div className="allocation-results-customer--section-title">Program Impact</div>
            <Group align="flex-start">
              <BasePaper titleContent="Program Subscriptions" className="allocation-results-customer--programs-accordion">
                {subscriptionsForCustomer.length && <Accordion defaultValue={subscriptionsForCustomer[0][0]}>
                  {subscriptionsForCustomer.map(([subId, subResults]) => (
                    <div key={subId}>
                      <Accordion.Item value={subId}>
                        <Accordion.Control>
                          <Text c="var(--color-green-2)">{programs.find(p => p.id === subResults.program_id)?.name || 'Unknown Program'}</Text>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <Stack gap={16}>
                            <Text fz={12} c="var(--color-grey-4)">
                              {timestampToNumericDate(subResults.start)} - {timestampToNumericDate(subResults.end)}
                            </Text>
                            <Pill bg='var(--color-grey-0)' c="var(--color-blue-1)" radius="sm">
                              {programs.find(p => p.id === subResults.program_id)?.data?.program_config?.accounting_period === 'hourly' ? '24/7' : 'Annual'} Program match
                            </Pill>
                            <Stack gap={8}>
                              <Group>
                                <IconKeyframeAlignHorizontal size="16px" color="var(--color-blue-2)" />
                                <div className="program-page--dark-info-text">
                                  Temporal Matching Interval: {programs.find(p => p.id === subResults.program_id)?.data?.program_config?.accounting_period === 'hourly' ? '1 hour' : 'annual'}
                                </div>
                              </Group>
                              <Group>
                                <IconBolt size="16px" color="var(--color-blue-2)" />
                                <div className="program-page--dark-info-text">
                                  {(subResults.generation_commit_pct * 100).toFixed(4)}% Program Generation commitment
                                </div>
                              </Group>
                            </Stack>
                            <Stack gap={4}>
                              <Text c="var(--color-blue-3)" fz={16}>Total energy matched</Text>
                              <Group align="baseline" gap={4}>
                                <Text fz={32} fw={700}>
                                  {Math.round(Math.min(subResults.allocated_generation_mwh, subResults.customer_consumption_mwh)).toLocaleString()}
                                </Text>
                                <Text fz={20} c="var(--color-grey-4)">MWh</Text>
                              </Group>
                              <Text fz={12} c="var(--color-grey-4)">
                                {((subResults.allocated_generation_mwh / (subResults.program_generation_mwh || 1)) * 100).toFixed(1)}%
                                Program Generation received
                              </Text>
                            </Stack>
                          </Stack>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </div>
                  ))}
                </Accordion>}
              </BasePaper>

              <BasePaper titleContent="What-if Program Impact" className="allocation-results-customer--what-if-impact">
                <Stack justify="space-between" gap="lg">
                  <BaseTable
                    columnNames={whatIfTableColumns}
                    rows={whatIfTableRows}
                    totalPages={0}
                    currentPage={0}
                    onPageChange={() => { }}
                  />
                  <CfeComparisonBarChart
                    programCfe={(((totalGridCfeWh || 0) + (totalProgramEnergyWh || 0)) / (totalLoadWh || 1))}
                    srpCfe={percentCfeAsSrp || 0}
                  />
                </Stack>
              </BasePaper>
            </Group>
          </>}
          <CustomerAllocationLegalDisclaimer />
        </Stack>
      }
    </div>
  </div>
};

export default AllocationResultsCustomer;