import { Center, Group, Paper, Select, Skeleton, Stack, Text, Title } from '@mantine/core';
import { IconArrowRight, IconChevronDown } from '@tabler/icons-react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { useUtilityInventorySummary } from 'amp/store/generators/hooks';
import { getLatestLoadAndGenDataDates, getViewingCustomerType } from 'amp/store/ui/selectors';
import { Point } from 'highcharts';
import { HighchartsReactRefObject } from 'highcharts-react-official';
import BaseChart from 'shared/components/Chart/baseChart';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { getCurrentUserRole, getMeasurementPreference } from 'shared/store/user/selectors';
import { AggregationTypes, GenerationByFuelAggEventData, IEmissionsFromGenerationData } from 'shared/types/aggregatedEvents';
import { AssetEventResolution } from 'shared/types/assetEvents';
import { CustomerType } from 'shared/types/customer';
import { MeasurementDisplayPreference, UserRole } from 'shared/types/user';
import { getLastYearEnd, getLastYearStart } from 'shared/utils/dates';
import { numberToSiFormat, numberToSiUnits } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import { makeEmissionsChartData, makeEmissionsChartOptions, makeGenerationChartData, makeGenerationChartOptions, makeGenerationTypePieChartOptions } from './chartHelpers';
import './style.css';

const ONE_MONTH_MILLIS = 1000 * 60 * 60 * 24 * 31;

export default function InventorySummaryView() {
  const [params, setParams] = useSearchParams();
  const startDateStr = params.get('s') || getLastYearStart().toISOString();
  const measurement = useAppSelector(getMeasurementPreference);
  let endDateStr = params.get('e') || getLastYearEnd().toISOString();
  let resolution = params.get('r') || '1d';
  const { latestGen } = useAppSelector(getLatestLoadAndGenDataDates);
  if (latestGen && !params.get('e')) {
    endDateStr = latestGen;
  }

  const startDate = useMemo(() => new Date(startDateStr), [startDateStr]);
  const endDate = useMemo(() => new Date(endDateStr), [endDateStr]);
  const isHourlyDisabled = Math.abs(startDate.valueOf() - endDate.valueOf()) > ONE_MONTH_MILLIS;
  const userRole = useAppSelector(getCurrentUserRole);
  const customerType = useAppSelector(getViewingCustomerType) as CustomerType;
  const nav = useAmpNav();

  if (isHourlyDisabled && resolution === '1h') {
    resolution = '1d';
  }

  const resolutionOptions = [
    { label: `Hourly${isHourlyDisabled ? ' (1 month max)' : ''}`, value: AssetEventResolution.HOUR, disabled: isHourlyDisabled },
    { label: 'Daily', value: AssetEventResolution.DAY },
    { label: 'Monthly', value: AssetEventResolution.MONTH },
    // { label: 'Yearly', value: AssetEventResolution.YEAR },
  ];

  const res = useUtilityInventorySummary<GenerationByFuelAggEventData>({
    startDate: startDateStr,
    endDate: endDateStr,
    aggregationType: AggregationTypes.GENERATION_BY_FUEL,
    resolution,
  })

  const emissionsRes = useUtilityInventorySummary<IEmissionsFromGenerationData>({
    startDate: startDateStr,
    endDate: endDateStr,
    aggregationType: AggregationTypes.EMISSIONS_FROM_GENERATION,
    resolution,
  });

  // send paginate request to get total count
  const totalSources = res.pagination?.last;

  const { totalGenerationWh, totalGenerationByFuel, aggregatedSeriesByFuel } = useMemo(() => makeGenerationChartData(res.data), [res.data]);
  const { totalEmissionsCo2e } = makeEmissionsChartData(emissionsRes.data, measurement);

  // this is the default landing page, re-direct users who don't have access
  // TODO: probably a better way to do this.
  useEffect(() => {
    const shouldRedirect = userRole === UserRole.REPORTER || customerType === CustomerType.UTILITY_CUSTOMER || customerType === CustomerType.UTILITY_PARENT_CUSTOMER;
    shouldRedirect && nav('/dashboard/reports');
  }, [userRole, customerType, nav]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  const isMetric = measurement === MeasurementDisplayPreference.METRIC;
  const lbToTon = isMetric ? 2_204.62 : 2_000;
  const lbConversionFactor = isMetric ? 2.20462 : 1

  const genChartRef = useRef<HighchartsReactRefObject>(null);
  const emissionsChartRef = useRef<HighchartsReactRefObject>(null);

  const genChartMouseOver = useCallback(function(this: Point, _event: Event) {
    const emissionsChart = emissionsChartRef.current?.chart;
    if (!emissionsChart) {
      return;
    }
    const emissionsPoint = emissionsChart.series[0].points.find(p => p.x === this.x);
    if (emissionsPoint && emissionsChart.xAxis[0]) {
      emissionsChart.xAxis[0].drawCrosshair(undefined, emissionsPoint);
    }
  }, [emissionsChartRef]);

  const emissionsChartMouseOver = useCallback(function(this: Point, _event: Event) {
    const genChart = genChartRef.current?.chart;
    if (!genChart) {
      return;
    }
    const genPoint = genChart.series[0].points.find(p => p.x === this.x);
    if (genPoint && genChart.xAxis[0]) {
      genChart.xAxis[0].drawCrosshair(undefined, genPoint);
    }
  }, [genChartRef]);

  const generationChartOptions = useMemo(() => makeGenerationChartOptions(aggregatedSeriesByFuel, genChartMouseOver), [aggregatedSeriesByFuel, genChartMouseOver]);
  const emissionsChartOptions = useMemo(() => makeEmissionsChartOptions(emissionsRes.data, measurement, emissionsChartMouseOver), [emissionsRes.data, measurement, emissionsChartMouseOver]);
  const pieChartOptions = useMemo(() => makeGenerationTypePieChartOptions(totalGenerationByFuel), [totalGenerationByFuel]);
  const formattedTotalGen = numberToSiUnits(totalGenerationWh);
  const formattedTotalPurchased = numberToSiUnits(totalGenerationByFuel['unspecified_purchase'] || 0);
  const formattedTotalOwned = numberToSiUnits(totalGenerationWh - (totalGenerationByFuel['unspecified_purchase'] || 0));
  const formattedTotalEmissions = numberToSiFormat(totalEmissionsCo2e / lbToTon);
  const totalGenMwh = totalGenerationWh / 1_000_000;
  const averageIntensity = totalEmissionsCo2e / (totalGenMwh || 1);
  const averageIntensityConverted = averageIntensity / lbConversionFactor;
  return (
    <>
      <Group ml="60px" mr="60px" mb="16px">
        <Select
          value={resolution}
          data={resolutionOptions}
          onChange={onResolutionChange}
          rightSection={<IconChevronDown size={20} />}
          w="180px"
        />
        <UtcDatePicker
          value={startDate}
          isStartDate={true}
          maxDate={endDate || new Date()}
          minDate={new Date(2021, 0, 1)}
        />
        <UtcDatePicker
          value={endDate}
          maxDate={new Date()}
          minDate={startDate || new Date(2021, 0, 1)}
        />
      </Group>

      <div className="inventory-overview--scroll-area">
        <Group w="100%" align="start" gap={16} mb="24px">
          <div className="inventory-overview--series-charts-container">
            <BasePaper className="inventory-overview--paper">
              <Skeleton visible={res.isLoading}>
                <BaseChart chartTitle="Generation Over Time" yAxisTitle="GWh Generated" overrideOptions={generationChartOptions} chartRef={genChartRef} />
              </Skeleton>
            </BasePaper>
            <BasePaper className="inventory-overview--paper">
              <BaseChart chartTitle="Carbon Emissions" overrideOptions={emissionsChartOptions} chartRef={emissionsChartRef} />
            </BasePaper>
          </div>

          <div className="inventory-overview--summary-stats-container">
            <Paper w="100%" h="100%" shadow="md">
              <Stack bg="#eef2f3" p="lg" gap="md">
                <Center>
                  <Title size="h5">Total Generation</Title>
                </Center>
                <Skeleton visible={res.isLoading}>
                  <Center>
                    <Group align="baseline" gap={0}>
                      <Title size="h1">{formattedTotalGen.slice(0, -1)}</Title>
                      <Text size="md" c="var(--color-grey-4)">{formattedTotalGen.slice(-1)}Wh</Text>
                    </Group>
                  </Center>
                </Skeleton>
                <Skeleton visible={res.isLoading}>
                  <Center>
                    <Text size="sm">{`${totalSources?.toLocaleString() || 0} Sources`}</Text>
                  </Center>
                </Skeleton>
                <Center>
                  <AmpLink className="inventory-overview--sources-link" to="/dashboard/inventory/sources">
                    <Group>
                      <Text size="md">See sources</Text>
                      <IconArrowRight />
                    </Group>
                  </AmpLink>
                </Center>
              </Stack>

              <Stack bg="#f8f9fa" p="xl">
                <Center>
                  <Title size="h3" c="#69758a">Generation Sources</Title>
                </Center>
                <Skeleton visible={res.isLoading}>
                  <Stack>
                    <Center>
                      <Group justify="space-between" w="100%" align="center" wrap='nowrap'>
                        <Text fz="sm">Owned Generation</Text>
                        <Group gap={4}>
                          <Text c="#2a384d" fw={700}>{formattedTotalOwned.slice(0, -1)}</Text>
                          <Text c="var(--color-grey-4)">{formattedTotalOwned.slice(-1)}Wh</Text>
                        </Group>
                      </Group>
                    </Center>
                    <Center>
                      <Group justify="space-between" w="100%" align="center" wrap='nowrap'>
                        <Text fz="sm">Purchased Power</Text>
                        <Group gap={4}>
                          <Text c="#2a384d" fw={700}>{formattedTotalPurchased.slice(0, -1)}</Text>
                          <Text c="var(--color-grey-4)">{formattedTotalPurchased.slice(-1)}Wh</Text>
                        </Group>
                      </Group>
                    </Center>
                  </Stack>
                </Skeleton>
              </Stack>

              <Stack bg="#eef2f3" p="xl">
                <Center>
                  <Title size="h3" c="#69758a">Emissions</Title>
                </Center>
                <Skeleton visible={emissionsRes.isLoading || res.isLoading}>
                  <Stack>
                    <Center>
                      <Group justify="space-between" w="100%" align="center" wrap='nowrap'>
                        <Text fz="sm">Total Emissions</Text>
                        <Stack gap={0} align="flex-end">
                          <Text c="#2a384d" fw={700}>{`${formattedTotalEmissions.value} ${formattedTotalEmissions.unitPrefix}`}</Text>
                          <Text c="var(--color-grey-4)" fz={12} ta="right">
                            {isMetric ? 'metric ' : ''}tons CO<sub>2</sub>e
                          </Text>
                        </Stack>
                      </Group>
                    </Center>
                    <Center>
                      <Group justify="space-between" w="100%" align="center" wrap='nowrap'>
                        <Text fz="sm">Carbon Intensity</Text>
                        <Stack gap={0} align="flex-end">
                          <Text c="#2a384d" fw={700}>{averageIntensityConverted.toFixed(1)}</Text>
                          <Text c="var(--color-grey-4)" fz={12} ta="right">
                            {isMetric ? 'kgs' : 'lbs'} CO<sub>2</sub>e/MWh
                          </Text>
                        </Stack>
                      </Group>
                    </Center>
                  </Stack>
                </Skeleton>
              </Stack>

              <Group p="md" bg="white">
                <Skeleton visible={res.isLoading}>
                  <BaseChart chartTitle="Generation Type" overrideOptions={pieChartOptions} />
                </Skeleton>
              </Group>

            </Paper>
          </div>
        </Group>
      </div>
    </>
  );
}