import { createSelector } from "reselect";
import { Customer, CustomerPropertyNamespace } from "shared/types/customer";
import { MeasurementDisplayPreference, UserProperty, UserRole, UserRoleHierarchy } from "shared/types/user";

import { RootState } from "store";

const root = (state: RootState) => state.user;

const bootstrap = createSelector(
  root,
  (user) => user.bootstrap,
);

const bootstrapStatus = createSelector(
  bootstrap,
  (boot) => boot.status,
);

export const getIsBootstrapNotStarted = createSelector(
  bootstrapStatus,
  (status) => status === 'NOT_STARTED',
);

export const getIsBootstrapLoading = createSelector(
  bootstrapStatus,
  (status) => status === 'NOT_STARTED' || status === 'IN_FLIGHT',
);

export const getIsBootstrapFailed = createSelector(
  bootstrapStatus,
  (status) => status === 'FAILED',
);

export const getWsToken = createSelector(
  bootstrap,
  (boot) => boot.wsToken,
)

const bootUserId = createSelector(
  bootstrap,
  (boot) => boot.userId,
);

const byId = createSelector(
  root,
  (user) => user.byId,
);

export const getCurrentUser = createSelector(
  bootUserId,
  byId,
  (userId, usersById) => usersById[userId || '']
);

export const getOpcos = createSelector(
  bootstrap,
  (boot) => boot.opcos,
);

export const getCurrentCustomer = createSelector(
  bootstrap,
  (boot) => boot.customer ? new Customer(boot.customer) : undefined,
);

export const getCurrentCustomerProperty = createSelector(
  getCurrentCustomer,
  (_: RootState, propertyName: string) => propertyName,
  (customer, propertyName) => customer?.attributes.find(a => a.name === propertyName)
);

export const getHasGate = createSelector(
  getCurrentCustomerProperty,
  (prop) => prop?.namespace === CustomerPropertyNamespace.GATES && prop.value === true
);

export const hasCertsInCustomerAllocation = (state: RootState) => getHasGate(state, 'has_certs_in_customer_allocation_report');

const overrideRole = createSelector(
  root,
  (state) => state.sadminRoleOverride,
)

const actualUserRole = createSelector(
  getCurrentUser,
  user => user?.role || UserRole.VISITOR,
);

export const getCurrentUserRole = createSelector(
  getCurrentUser,
  overrideRole,
  (user, overrideRole) => overrideRole || user?.role || UserRole.VISITOR,
);

export const getRawHasRolePermission = createSelector(
  actualUserRole,
  (_: RootState, targetRole: UserRole) => targetRole,
  (userRole, targetRole) => {
    const userRoleValue = UserRoleHierarchy[userRole] || 0;
    const requiredRoleValue = UserRoleHierarchy[targetRole] || 99;
    return userRoleValue >= requiredRoleValue;
  },
);

export const getHasRolePermission = createSelector(
  getCurrentUserRole,
  (_: RootState, targetRole: UserRole) => targetRole,
  (userRole, targetRole) => {
    const userRoleValue = UserRoleHierarchy[userRole] || 0;
    const requiredRoleValue = UserRoleHierarchy[targetRole] || 99;
    return userRoleValue >= requiredRoleValue;
  },
);

const getCurrentUserProperties = createSelector(
  getCurrentUser,
  (user) => user ? user.attributes : [],
);

const getCurrentUserProperty = createSelector(
  getCurrentUserProperties,
  (_: RootState, propertyName: string) => propertyName,
  (props, propName) => props.find(({name}) => name === propName),
);

const getCurrentUserSetting = createSelector(
  getCurrentUserProperty,
  (prop) => prop?.namespace === 'SETTINGS' ? prop : undefined
);

export const getFavoriteCustomerIds = createSelector(
  (state: RootState) => getCurrentUserSetting(state, UserProperty.FAVE_CUST_IDS),
  (prop) => {
    if (!prop) {
      return [];
    }
    if (typeof prop.value !== 'object' || prop.value === null) {
      return [];
    }
    if (Array.isArray(prop.value)) {
      return prop.value as string[];
    }
    return []
  }
)

export const getMeasurementPreference = createSelector(
  (state: RootState) => getCurrentUserSetting(state, UserProperty.MEASUREMENT_PREF),
  (pref) => {
    const rawVal = pref?.value.toString() || '';
    if (Object.values(MeasurementDisplayPreference).includes(rawVal as MeasurementDisplayPreference)) {
      return rawVal as MeasurementDisplayPreference;
    } else {
      return MeasurementDisplayPreference.IMPERIAL;
    }
  }
)

export const getUserById = createSelector(
  byId,
  (_: RootState, userId: string) => userId,
  (usersById, userId) => usersById[userId],
)