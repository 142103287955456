import { queries } from "shared/api/queries";
import { IDashboardPaginationResponseV2 } from "shared/types/api";
import { ICustomerReport } from "shared/types/report";

interface IListCustomerReportsArgs {
  page: number;
  perPage: number;
  customerId: string;
}


const adminCustomerReportsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    adminPaginateCustomerReports: build.query<IDashboardPaginationResponseV2<ICustomerReport>, IListCustomerReportsArgs>({
      query: ({page, perPage, customerId}) => {
        const qs = new URLSearchParams();
        qs.set('page', page.toString());
        qs.set('per_page', perPage.toString());
        qs.set('customer_id', customerId);

        return {
          url: `/admin/customer_reports/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [{type: 'CustomerReport', id: 'PAGE'}],
    }),

    adminFetchCustomerReport: build.query<{data: ICustomerReport}, string>({
      query: (id) => `/admin/customer_reports/${id}`,
      providesTags: (res) => res ? [{type: 'CustomerReport', id: res.data.id}] : [],
    }),
  }),
  overrideExisting: false,
});


export const {
  useAdminFetchCustomerReportQuery,
  useAdminPaginateCustomerReportsQuery,
} = adminCustomerReportsQueries;