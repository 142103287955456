import { Box, Center, Group, Loader, Pill, Select, Skeleton } from '@mantine/core';
import { IconChevronDown, IconMapPin, IconSitemap } from '@tabler/icons-react';
import { Map, Marker } from 'mapbox-gl';
import { useEffect, useMemo, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useFetchAssetEventsQuery } from 'amp/api/assetEvents';
import { getAsDOMElement } from 'amp/components/MapIcons/large';
import { useMultipleAssetForecast } from 'amp/store/forecastRuns/hooks';
import { useSubscriptionsPage } from 'amp/store/programs/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { sum, uniq } from 'ramda';
import { IStaticDataCookiesRes, useFetchStaticDataCookiesQuery } from 'shared/api/users';
import BaseChart from 'shared/components/Chart/baseChart';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { MAPBOXGL_ACCESS_TOKEN } from 'shared/constants/resources';
import { useCustomer } from 'shared/store/customers/hooks';
import { AssetEventResolution, IEnergyConsumptionData } from 'shared/types/assetEvents';
import { ICustomer, getCustomerAddress, getCustomerDefaultAllocationPctTenThousandths, getCustomerLatLng, getCustomerUtilityAccountId } from 'shared/types/customer';
import { getThisYearEnd, getThisYearStart } from 'shared/utils/dates';
import { hrFloat } from 'shared/utils/math';
import { useAppSelector } from 'store';
import './style.css';


const setCookies = ({ cookies, expires_at }: IStaticDataCookiesRes) => {
  const domainWithPort = `.${document.location.host.split('.').slice(-2).join('.')}`;
  const domain = domainWithPort.split(':')[0];
  Object.entries(cookies).forEach(([cookieName, cookieValue]) => {
    document.cookie = `${cookieName}=${cookieValue};expires=${expires_at};path=/;domain=${domain};`;
  });
};

export const CloudfrontCookies = ({ cfnCookies }: { cfnCookies?: IStaticDataCookiesRes }) => {
  useEffect(() => {
    if (cfnCookies) {
      setCookies(cfnCookies);
    }
  }, [cfnCookies]);

  return null;
};


const resolutionOptions = [
  { label: 'Hourly', value: AssetEventResolution.HOUR },
  { label: 'Daily', value: AssetEventResolution.DAY },
  { label: 'Monthly', value: AssetEventResolution.MONTH },
  // { label: 'Yearly', value: AssetEventResolution.YEAR },
];

const AccountEnergyConsumption = ({ subAccount }: { subAccount: ICustomer }) => {
  const [params, setParams] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);
  const startDateStr = params.get('s') || getThisYearStart().toISOString();
  const endDateStr = params.get('e') || getThisYearEnd().toISOString();
  const resolution = params.get('r') || '1d';

  const consumptionRes = useFetchAssetEventsQuery({
    startDate: startDateStr,
    endDate: endDateStr,
    resolution,
    eventType: 'metered_consumption',
    customerId: subAccount.id,
  });

  const forecastRes = useMultipleAssetForecast(uniq(consumptionRes.data?.data.map(d => d.asset_id) || []), getThisYearEnd().getFullYear());
  const forecastData = useMemo(() => forecastRes.data?.filter(({ start_date }) => {
    return new Date(start_date) >= new Date(startDateStr) && new Date(start_date) <= new Date(endDateStr);
  }) || [], [forecastRes.data, startDateStr, endDateStr]);

  const consumptionData = consumptionRes.data?.data;
  const [consumptionSeriesData, totalConsumptionMwh] = useMemo(() => {
    if (!consumptionData) return [];

    const dataByStartDate: Record<string, IEnergyConsumptionData[]> = {};

    const seriesData: number[][] = [];
    let totalKwh = 0;

    consumptionData.forEach(event => {
      if (event.start_date in dataByStartDate) {
        dataByStartDate[event.start_date].push(event.data as IEnergyConsumptionData);
      } else {
        dataByStartDate[event.start_date] = [event.data as IEnergyConsumptionData];
      }
    });

    Object.entries(dataByStartDate).forEach(([startDateStr, eventData]) => {
      const sumConsumedKwh = hrFloat(sum(eventData.map(datum => datum.sum_consumed_kwh)));
      seriesData.push([new Date(startDateStr).valueOf(), hrFloat(sumConsumedKwh / 1000)]);
      totalKwh += sumConsumedKwh;
    });

    return [seriesData, Math.round(totalKwh / 1_000)];
  }, [consumptionData]);

  const [forecastSeriesData, totalForecastYTDMWh, totalForecastMWh] = useMemo(() => {
    if (!forecastData) return [];

    let totalWh = 0;
    let totalWhYTD = 0;

    // Forecast data is always hourly, convert to daily:
    const byDay: Record<string, number> = {};
    forecastData.forEach(event => {
      const date = new Date(event.start_date);
      date.setHours(0);
      date.setMinutes(0);
      if (!byDay[date.toISOString()]) {
        byDay[date.toISOString()] = 0;
      }
      byDay[date.toISOString()] += event.y_axis_value_wh;
      totalWh += event.y_axis_value_wh;
      totalWhYTD += event.y_axis_value_wh;
    });

    const seriesData = Object.entries(byDay).map(([isoString, valWh]) => {
      const dayDate = new Date(isoString);
      return [dayDate.getTime(), valWh / 1_000_000];
    });

    return [seriesData.sort((x1, x2) => x1[0] - x2[0]), Math.round(totalWhYTD / 1_000_000), Math.round(totalWh / 1_000_000)];
  }, [forecastData]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  const consumptionChartOptions: Highcharts.Options = {
    series: [
      {
        name: 'Consumption actual',
        data: consumptionSeriesData,
        type: 'area',
        color: '#72B3FF',
        lineWidth: 1,
      },
      {
        name: 'Consumption projection',
        data: forecastSeriesData,
        type: 'line',
        color: 'var(--color-blue-1)',
        dashStyle: 'LongDash',
        lineWidth: 1,
      }
    ],
    legend: {
      enabled: true,
      useHTML: true,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0.25, 'rgba(202, 219, 244, 0.5)'], // start
            [1, 'rgba(202, 219, 244, 0)'] // end
          ]
        },
      },
    },
    tooltip: {
      pointFormatter: function () {
        return `<b>${this.y?.toLocaleString() || ''}</b> MWh`;
      },
    },
    yAxis: [
      {
        title: {
          text: 'Electricity consumption (MWh)',
          useHTML: true,
        }
      }
    ]
  };

  const cookiesRes = useFetchStaticDataCookiesQuery(oci);
  const isLoading = forecastRes.loading || consumptionRes.isLoading;
  const percentVsForecasted = (totalForecastYTDMWh && totalConsumptionMwh) ? totalConsumptionMwh / totalForecastYTDMWh : 0;

  return (
    <BasePaper
      titleContent={<div>Energy Consumption</div>}
      actions={
        <Group ml="lg" mr="lg" fw="400">
          <Select
            value={resolution}
            data={resolutionOptions}
            onChange={onResolutionChange}
            rightSection={<IconChevronDown size={20} />}
            w="120px"
            className="customer-view-consumption-resolution--select"
          />
          <UtcDatePicker
            value={new Date(startDateStr)}
            isStartDate={true}
            maxDate={new Date(endDateStr) || undefined}
            minDate={new Date(2021, 1, 1)}
          />
          -
          <UtcDatePicker
            value={new Date(endDateStr)}
            minDate={new Date(startDateStr) || new Date(2021, 1, 1)}
          />
        </Group>
      }
    >
      <CloudfrontCookies cfnCookies={cookiesRes.data} />

      <div className="customer-view-consumption-topline-metrics--container">
        <div className="customer-view-consumption-topline-metric--container">
          <div className="customer-view-consumption-topline-metric-value--container">
            <Skeleton visible={isLoading} width="fit-content">
              <div className="customer-view-consumption-topline-metric--value">{totalConsumptionMwh?.toLocaleString()}</div>
            </Skeleton>
            <div className="customer-view-consumption-topline-metric--label">MWh</div>
          </div>
          <div className="customer-view-consumption-topline-metric-explanation--container">
            <div className="customer-view-consumption-topline-metric-explanation--label">Total consumption YTD</div>
            <Skeleton visible={isLoading}>
              <div className="customer-view-vs-forecast--container">
                <Pill ml={8} radius="sm" c={percentVsForecasted <= 1 ? "var(--color-teal-9)" : "var(--color-se-red-1)"} bg={percentVsForecasted <= 1 ? "var(--color-teal-0)" : "var(--color-red-1)"}>
                  {`${percentVsForecasted > 1 ? '+' : ''} ${((percentVsForecasted - 1) * 100).toLocaleString(undefined, {style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1})} %`}
                </Pill>
                <small className="customer-view-vs-forecast--explanation">vs projection</small>
              </div>
            </Skeleton>
          </div>
        </div>

        <div className="customer-view-consumption-topline-metric--container">
          <div className="customer-view-consumption-topline-metric-value--container">
            <Skeleton visible={isLoading} width="fit-content">
              <div className="customer-view-consumption-topline-metric--value highlight">{totalForecastMWh?.toLocaleString()}</div>
            </Skeleton>
            <div className="customer-view-consumption-topline-metric--label">MWh</div>
          </div>
          <div className="customer-view-consumption-topline-metric-explanation--container">
            <div className="customer-view-consumption-topline-metric-explanation--label">Consumption year-on-year projection</div>
          </div>

        </div>
      </div>

      <Skeleton visible={isLoading}>
        <BaseChart overrideOptions={consumptionChartOptions} />
      </Skeleton>
    </BasePaper>
  );
};


const SubAccountDetails = ({ subAccount }: { subAccount: ICustomer }) => {
  const map = useRef<Map | null>(null);

  // TODO: limit of 50 subscriptions
  const parentSubscriptionsRes = useSubscriptionsPage({ page: 1, perPage: 50, childCustomerId: subAccount.parent_id || '' });
  const subscriptions = parentSubscriptionsRes.data?.filter(sub => {
    const subAccountConfig = sub.data.configuration.sub_account_to_allocation_percent_ten_thousandths;
    return subAccountConfig ? subAccountConfig[subAccount.id] : true;
  }) || [];
  const parentSubscriptionsCount = subscriptions.length;

  const address = getCustomerAddress(subAccount) || 'No address';
  const accountCommitment = getCustomerDefaultAllocationPctTenThousandths(subAccount);

  useEffect(() => {
    if (!map.current) {
      map.current = new Map({
        accessToken: MAPBOXGL_ACCESS_TOKEN,
        container: 'sub-account-page--map-container',
        attributionControl: false,
        interactive: false,
        center: [-83, 33], // [lng, lat] is expected
        zoom: 4.3,
        style: 'mapbox://styles/mapbox/light-v10',
        renderWorldCopies: false,
      });

      const latLng = getCustomerLatLng(subAccount);
      if (latLng) {
        const lng = typeof latLng[1] === 'number' ? latLng[1] : parseFloat(latLng[1]);
        const lat = typeof latLng[0] === 'number' ? latLng[0] : parseFloat(latLng[0]);
        new Marker(getAsDOMElement(false)).setLngLat([lng, lat]).addTo(map.current);
        map.current.setCenter([lng, lat]);
      }
    }
  });

  return (
    <BasePaper titleContent={<div>Sub-account Details</div>}>
      <div className="sub-account-page--details-row">
        <Box h="160px" w="280px">
          <div id="sub-account-page--map-container" />
        </Box>
        <div className="sub-account-page--metadata-container">
          <div className="sub-account-page--account-name">{subAccount.name}</div>
          <div className="sub-account-page--location-row">
            <IconMapPin size="20px" />
            <div className="sub-account-page--location-text">
              {address}
            </div>
          </div>
          <div className="sub-account-page--id-text">
            Sub-account ID: {getCustomerUtilityAccountId(subAccount) || <em>unknown</em>}
          </div>
        </div>
        <div className="sub-account-page--topline-numbers-container">
          <div className="sub-account-page--topline-numbers-row">
            <Skeleton visible={parentSubscriptionsRes.isLoading}>
              <div className="sub-account-page--topline-stat-container">
                <div className="sub-account-page--subscription-count-container">
                  <IconSitemap size={28} color="var(--color-green-2)" />
                  <div className="sub-account-page--topline-stat-number">
                    {parentSubscriptionsCount}
                  </div>
                </div>
                <div className="sub-account-page--topline-stat-subtext">
                  {`Program subscription${parentSubscriptionsCount !== 1 ? 's' : ''}`}
                </div>
              </div>
            </Skeleton>
            <div className="sub-account-page--topline-stat-container">
              <div className="sub-account-page--topline-stat-number">
                {accountCommitment ? (accountCommitment / 10_000) : 0}%
              </div>
              <div className="sub-account-page--topline-stat-subtext">
                Default Subscribed Generation %
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasePaper>
  );
}


export default function CustomerAccountView() {
  const { accountId = '' } = useParams<{ accountId: string }>();
  const subAccountRes = useCustomer(accountId);
  const subAccount = subAccountRes.data;

  if (subAccountRes.isLoading || !subAccount) {
    return <Box w="100%" h={400}>
      <Center>
        <Loader />
      </Center>
    </Box>
  }

  return (
    <div className="customer-account-page--scroll-area">
      <SubAccountDetails subAccount={subAccount} />
      <AccountEnergyConsumption subAccount={subAccount} />
    </div>
  );
}